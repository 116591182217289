import * as types from '../constants/actionTypes'

export const setCommunitiesMapBounds = ({
  southBound,
  eastBound,
  offsetLat,
  offsetLng,
}) => ({
  type: types.SET_COMMUNITIES_MAP_BOUNDS,
  bounds: { southBound, eastBound, offsetLat, offsetLng },
})

export const setCommunitiesListIndex = (index) => ({
  type: types.SET_COMMUNITIES_LIST_INDEX,
  index,
})

export const setProspect = (prospect) => ({
  type: types.SET_PROSPECT,
  prospect,
})

export const setProspectReservations = (reservations) => ({
    type: types.SET_PROSPECT_RESERVATIONS,
    reservations
  })

export const setAnonymousProspect = (prospect) => ({
  type: types.SET_ANONYMOUS_PROSPECT,
  anonymousProspect: prospect,
})

export const setAgent = (agent) => ({
  type: types.SET_AGENT,
  agent,
})

export const setFloorplansView = ({ floorplansView }) => ({
  type: types.SET_FLOORPLANS_VIEW,
  floorplansView,
})

export const setSiteplanName = ({ siteplanName }) => ({
  type: types.SET_SITEPLAN_NAME,
  siteplanName,
})

export const setSiteplanId = ({ siteplanId }) => ({
  type: types.SET_SITEPLAN_ID,
  siteplanId,
})

export const setInfoModal = ({
  openInfoModal,
  infoModalMsg,
  infoModalTitle,
  progressModalMsg,
}) => ({
  type: types.SET_INFO_MODAL,
  openInfoModal,
  infoModalMsg,
  infoModalTitle,
  progressModalMsg,
})

export const resetInfoModal = () => ({
  type: types.RESET_INFO_MODAL,
})

export const toggleFavMenu = () => ({
  type: types.TOGGLE_FAV_MENU,
})

export const toggleCompareFavorites = () => ({
  type: types.TOGGLE_COMPARE_FAVORITES,
})
