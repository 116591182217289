import { apply, getPath } from '@anewgo/functions'
import { siteplans } from '@anewgo/utils'
import { apolloClient } from '../store'
import { DELETE_PROSPECT_FAVORITE_BY_EMAIL, ADD_PROSPECT_FAVORITES } from '../graphql'
const { isInventoryLot } = siteplans


export const uniqueIndexForFavorite = ({ community, plan, elevation, lot, garagePosition, duplicateNum }) => {
  const communityId = getPath('id')(community)
  const planId = getPath('id')(plan)
  const elevationId = getPath('id')(elevation) || null
  const inventoryId = apply(isInventoryLot)(lot) ? lot.inventory.id : null

  return `${communityId}:${planId}:${elevationId}:${inventoryId}:${garagePosition || null}:${duplicateNum || 0}`
}

export const deleteFavoriteFromDB = (id, clientName, prospectEmail) => {
  return apolloClient
    .mutate({
      mutation: DELETE_PROSPECT_FAVORITE_BY_EMAIL,
      variables: {
        clientName,
        id,
        prospectEmail
      },
    })
    .then((data) => {
      return getPath('data', 'deleteFavoriteForProspect')(data)
    })
}

export const addProspectFavorites = (clientName, email, favorites) => {
  return apolloClient
        .mutate({
          mutation: ADD_PROSPECT_FAVORITES,
          variables: {
            clientName,
            email,
            favorites: favorites.map((fav) => ({
              communityId: getPath('community', 'id')(fav),
              planId: getPath('plan', 'id')(fav),
              elevationId: getPath('elevation', 'id')(fav),
              lotId: getPath('lot', 'id')(fav),
              schemeId: getPath('scheme', 'id')(fav),
              colorSelections: getPath('colorSelections')(fav),
              fpOptSelections: fav.fpOpts ? formatFpOptsForUI(getPath('fpOpts')(fav)) : {},
              source: 'camber',
              garagePosition: getPath('garagePosition')(fav),
              isMirrored: getPath('isMirrored')(fav),
            })),
          },
        })
        .then((data) => {
          return getPath('data', 'addFavoritesForProspect')(data)
        })
}

export const formatFpOptsForUI = (fpOpts) => {
  const fpOptsObject = {}
  fpOpts.forEach((story) => {
    fpOptsObject[story.fnum] = story.opts
  })
  return fpOptsObject
}