import { initTracker, trackAction, trackPage, userIdentifier } from '@anewgo/tracking'
import { EVT_VIEW_PAGE } from '../constants/eventTracking'

export function generateSelectionTracking(
  clientName,
  prospect,
  anonymousProspect,
  homeIdentifier,
  selection,
  mainAction,
  mainEvent,
  selectColorsEvent,
  selectFpOptsEvent
) {
  const { community, plan, elevation, lot, scheme, colorSelections, fpOptSelections } =
    selection

  // main  event
  const brochureProps = {
    homeIdentifier,
    communityId: community?.id,
    communityName: community?.name,
    planId: plan?.id,
    planName: plan?.name,
    elevationId: elevation?.id,
    elevationCaption: elevation?.caption,
    lotId: lot?.id,
    lotName: lot?.name,
    lotAddress: lot?.address,
    inventoryId: lot?.inventory?.id,
    schemeId: scheme?.id,
    schemeName: scheme?.name,
    ...mainAction,
  }
  trackAction(
    mainEvent,
    brochureProps,
    clientName,
    userIdentifier(prospect, anonymousProspect)
  )

  let colorSelectionMaterials = Object.keys(colorSelections)
  // track selected colors
  if (colorSelections) {
    colorSelectionMaterials.forEach((materialId) => {
      const color = colorSelections[materialId]
      const colorTrackingProps = {
        homeIdentifier,
        materialId,
        colorPaletteId: color?.paletteId,
        colorName: color?.name,
        colorType: color?.type,
        colorMasonryLib: color?.masonryLib,
        source: 'click',
        paletteSelectionId: color ? color.id : null,
        colorId: color ? color.colorId : null,
        brickId: color ? color.brickId : null,
        stoneId: color ? color.stoneId : null,
        customOverlayId: color ? color.customOverlayId : null,
      }
      trackAction(
        selectColorsEvent,
        colorTrackingProps,
        clientName,
        userIdentifier(prospect, anonymousProspect)
      )
    })
  }

  if (scheme) {
    scheme.materials.forEach((color) => {
      if (colorSelectionMaterials.includes(color.materialId.toString())) return
      const color2 = {
        ...color,
        paletteId:
          color.paletteIds !== null && color.paletteIds.length > 0
            ? color.paletteIds[0]
            : null,
      }
      const colorTrackingProps = {
        homeIdentifier,
        materialId: color.materialId,
        colorPaletteId: color2?.paletteId,
        colorName: color2?.name,
        colorType: color2?.type,
        colorMasonryLib: color2?.masonryLib,
        source: 'scheme',
        schemeElementId: color ? color.id : null,
        colorId: color ? color.colorId : null,
        brickId: color ? color.brickId : null,
        stoneId: color ? color.stoneId : null,
        customOverlayId: color ? color.customOverlayId : null,
      }
      trackAction(
        selectColorsEvent,
        colorTrackingProps,
        clientName,
        userIdentifier(prospect, anonymousProspect)
      )
    })
  }
  // track selected floorplan options
  if (fpOptSelections) {
    Object.keys(fpOptSelections).forEach((fnum) => {
      const fpOpts = fpOptSelections[fnum].opts
      fpOpts.forEach((fpOpt) => {
        const fpTrackingProps = {
          homeIdentifier,
          floorplanOptionId: fpOpt?.id,
          floorplanOptionName: fpOpt?.name,
          floorplanOptionFnum: fpOpt?.fnum,
        }
        trackAction(
          selectFpOptsEvent,
          fpTrackingProps,
          clientName,
          userIdentifier(prospect, anonymousProspect)
        )
      })
    })
  }
}

function trackPageWrapper({
  prospect,
  anonymousProspect,
  selectedClient,
  location,
  title,
  selectedCommunity,
  selectedPlan,
  selectedElevation,
}) {
  const user = userIdentifier(prospect, anonymousProspect)

  trackAction(
    EVT_VIEW_PAGE,
    {
      title,
      communityId: selectedCommunity?.id,
      communityName: selectedCommunity?.name,
      planId: selectedPlan?.id,
      planName: selectedPlan?.name,
      elevationId: selectedElevation?.id,
      elevationName: selectedElevation?.name,
    },
    selectedClient.altName,
    user
  )

  trackPage(location, title, selectedClient?.altName, user)
}

export { initTracker, trackAction, userIdentifier, trackPageWrapper as trackPage }
