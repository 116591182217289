import { getPath } from '@anewgo/functions'

/**
 * @description - sorts the list in accordance to communityName, elevation name, and caption.
 * @param {Array[Objects]} favorites 
 * 
 * @constraints - community and elevation name is always not null
 *              - caption can be null.
 * 
 * @returns {Array[Object]} - sorted favorites.
 */
export const sortFavorites = (favorites) => {
  favorites.sort( (favoriteOne, favoriteTwo) => {
    const communityNameOne    = getPath("favoriteOne", "community", "name")(favoriteOne)
    const elevationNameOne    = getPath("favoriteOne", "community", "planName")(favoriteOne)
    const elevationCaptionOne = getPath("favoriteOne", "community", "caption")(favoriteOne)

    const communityNameTwo    = getPath("favoriteTwo", "community", "name")(favoriteTwo)
    const elevationNameTwo    = getPath("favoriteTwo", "community", "planName")(favoriteTwo)
    const elevationCaptionTwo = getPath("favoriteTwo", "community", "caption")(favoriteTwo)
    

    if (elevationCaptionOne == null && elevationCaptionTwo != null) {
      return -1
    } else if (elevationCaptionOne != null && elevationCaptionTwo == null) {
      return 1
    } else if (elevationCaptionOne == null && elevationCaptionTwo == null) {
      return 0
    }

    if(communityNameOne === communityNameTwo) {
      if (elevationNameOne === elevationNameTwo) {
 
        return elevationCaptionOne.localeCompare(elevationCaptionTwo, undefined, {numeric: true})
      }

      return elevationNameOne.localeCompare(elevationNameTwo, undefined, {numeric: true})
    }

    return communityNameOne.localeCompare(communityNameTwo, undefined, {numeric: true})
  })

  return favorites
}
