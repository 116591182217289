import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  noMatchBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  noMatchText: {
    position: 'absolute',
    bottom: 100,
    textShadow: '-3px 1px 6px rgba(0, 0, 0, 1)',
    color: 'white',
    fontSize: 40,
    width: '100%',
    textAlign: 'center',
  },
})

const NoMatch = ({classes, bgImg, msg}) => {
  const localStyles = {
    bg: {
      backgroundImage: `url(${bgImg})`,
    }
  }
  return (
    <div className={classes.noMatchBg} style={localStyles.bg}>
      <div className={classes.noMatchText}>{msg}</div>
    </div>
  )
}

export default withStyles(styles)(NoMatch)
