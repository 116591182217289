import { useContext } from 'react';
import ReactDom from 'react-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { LoadingSpinner } from '@anewgo/icons/dist'
import { ConfigContext } from '../store'

const styles = theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1000,
  },
  loadingText: {
    fontSize: '1rem',
    fontWeight: 'normal',
  },
})

const Loading = ({ classes }) => {
  const uiConfig = useContext(ConfigContext)
  const { appRef } = uiConfig
  if (!appRef.current) {
    return null
  }
  const localStyles = { top: 0, left: 0, display: 'flex' }
  return ReactDom.createPortal(
    <div className={classes.root} style={localStyles}>
      <LoadingSpinner />
      <div className={classes.loadingText}>
        <Typography variant="button">Loading...</Typography>
        <LinearProgress />
      </div>
    </div>,
    appRef.current
  )
}

export default withStyles(styles)(Loading)
