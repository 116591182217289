import { createContext } from 'react'
import { ApolloClient } from '@apollo/client/core'
import { ApolloLink, HttpLink, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache'
import { getMainDefinition } from '@apollo/client/utilities'

// redux
import { AUTHENTICATE_API_KEY } from '../graphql'
import { createStore } from 'redux'
import { rootReducer } from '../reducers'


export const ConfigContext = createContext()

const NEXUS_URI = process.env.REACT_APP_NEXUS_URI
const FLAGSHIP_API_KEY = process.env.REACT_APP_FLAGSHIP_API_KEY

const uniqueKeysByTypename = {
  Elevation: ['communityId', 'planId', 'id'],
  ElevationFilterTag: ['category', 'tag'],
  ElevationFloorplans: ['communityId', 'planId', 'elevationId'],
  // FloatRange: ['min', 'max'],
  Floorplan: ['id', 'groupId', 'standard'],
  // IntRange: ['min', 'max'],
  InventoryExteriorConfiguration: ['inventoryId'],
  Layer: ['src'],
  LotSiteplanInfo: ['lotId'],
  MaterialPalette: ['communityId', 'planId', 'elevationId', 'materialId'],
  PaletteElementSelection: ['inventoryId', 'elementId'],
  Photo: ['src', 'listIndex'],
  Plan: ['communityId', 'id'],
  PlanElevation: ['planId', 'elevationId'],
  SiteplanInfo: ['siteplanId'],
  Story: ['id', 'communityId', 'planId', 'elevationId'],
}

const dataIdFromObject = object => {
  const { __typename } = object
  const uniqueKeys = uniqueKeysByTypename[__typename]
  if (uniqueKeys) {
    const tuple = uniqueKeys.map(key => object[key]).join(':')
    return `${__typename}:${tuple}`
  }

  return defaultDataIdFromObject(object)
}

// Module-level variable that the Apollo client's authLink will reference.
let apiToken

const httpLink = new HttpLink({ uri: NEXUS_URI })

const authLink = new ApolloLink((operation, forward) => {
  // On every GraphQL request, conditionally add an authorization header if we have an API token.
  if (apiToken) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${apiToken}`,
      },
    })
  }

  return forward(operation)
})

const websocketUri =
  process.env.REACT_APP_NEXUS_API_SUBSCRIPTIONS_SERVER || 'ws://localhost:5001/graphql'

const wsLink = new WebSocketLink({
  uri: websocketUri,
  options: {
    reconnect: true,
    // Note that we're assigning a function to connectionParams. This is to make sure we try reconnecting with the newly
    // signed-in user's token after we've failed to connect with the "null" token. See this github issue and solution:
    // https://github.com/apollographql/apollo-link/issues/197
    connectionParams: () => ({
      token: apiToken || '',
    }),
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

// Set up an Apollo client instance that will initially not have an API token in the header, and
// then always have it after retrieving it.
export const apolloClient = new ApolloClient({
  link: authLink.concat(ApolloLink.from([splitLink])),
  cache: new InMemoryCache({
    dataIdFromObject,
  }),
})

export const initializeApiToken = async () => {
  const { data } = await apolloClient.query({
    fetchPolicy: 'no-cache',
    query: AUTHENTICATE_API_KEY,
    variables: { apiKey: FLAGSHIP_API_KEY },
  })

  if (!apiToken) {
    // Refresh the token every 11 hours 55 minutes, to avoid overlapping with the 12-hour token
    // expiration.
    setInterval(initializeApiToken, 42900000)
  }

  apiToken = data.authenticateApiKey
}

// redux config
let store
export const configureStore = () => {
  store = createStore(rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../reducers', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }
  return store
}

export const getStore = () => {
  return store
}
