import gql from 'graphql-tag'
import * as commonFields from './commonFields'

export const siteplan = gql`
  fragment SiteplanFields on Siteplan {
    id
    name
    displayName
    lotFontSize
    lotMetric
    lotWidth
    lotHeight
    master
    src
    active
    unreleasedLotsHandle
    showUnreleasedLotNumber
    lotLegend(clientName: $clientName) {
      id
      code
      name
      hex
    }
    hotspots {
      id
      siteplanId
      name
      x
      y
      width
      height
      description
      thumb
      assets {
        id
        listIndex
        src
        videoUrl
        description
      }
    }
    lots(clientName: $clientName) {
      id
      size
      name
      dataName
      address
      salesStatus
      premium
      siteplanName(clientName: $clientName)
      siteplanDisplayName(clientName: $clientName)
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
      excludedPlanElevations(clientName: $clientName) {
        planId
        elevationId
        planName
        elevationCaption
      }
      availablePlanElevations(clientName: $clientName) {
        planId
        elevationId
        planName
        elevationCaption
      }
      availableSchemes(clientName: $clientName) {
        id
        name
      }
      inventory(clientName: $clientName) {
        id
        beds
        baths
        features
        sqft
        price
        planId
        closingDate
        constructionStatus
        garagePosition
        includedFloorplans(clientName: $clientName) {
          id
          base
          bath
          bed
          cost
          fnum
          labelSvg
          lastUpdateTime
          listIndex
          name
          notes
          optional
          renderIndex
          size
          src
          src2
          standard
        }
        plan(clientName: $clientName) {
          id
          communityId
          name
          defaultElevationId
          description
        }
        elevation(clientName: $clientName) {
          id
          mirrorElevationId
          communityId
          planId
          planName
          caption
          bed
          base
          bath
          size
          cost
          thumb
          svgMirroring
          stories(clientName: $clientName) {
            id
            fnum
          }
        }
        elevationId
        photoFolder
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
        exteriorConfiguration(clientName: $clientName) {
          inventoryId
          scheme {
            id
            name
            materials(clientName: $clientName) {
              id
              materialId
              materialCategory
              material
              name
              hex
              type
              swatch
              masonryLib
            }
          }
          paletteElementSelections {
            inventoryId
            elementId
            paletteId
            paletteSelection {
              id
              name
              hex
              type
              swatch
              masonryLib
            }
          }
        }
      }
    }
    svg {
      viewBox {
        x
        y
        width
        height
      }
      style
      frame {
        x
        y
        width
        height
      }
      shapes {
        tagName
        attributes {
          className
          dataName
          x
          y
          width
          height
          transform
          points
          d
        }
      }
    }
  }
`

export const community = gql`
  fragment CommunityFields on Community {
    id
    name
    thumb
    bonafide
    buildYourLot
    caption
    colormtd
    description
    pricing
    logo
    longitude
    latitude
    address
    sortType
    sortOrder
    cityLocation(clientName: $clientName) {
      id
      name
      customName
      region {
        __typename
        ... on USCityRegionInfo {
          stateCode
          zipCode
        }
        ... on CANCityRegionInfo {
          provinceCode
          postCode
        }
      }
    }
    agents(clientName: $clientName) {
      id
      email
      phone
      firstName
      lastName
      picture
    }
  }
`

export const plan = gql`
  fragment PlanFields on Plan {
    ${commonFields.plan}
    ${commonFields.ranges}
  }
`

export const elevation = gql`
  fragment ElevationFields on Elevation {
    ${commonFields.elevation}
    ${commonFields.ranges}
    ${commonFields.elevationTags}
  }
`

export const lot = gql`
  fragment LotFields on Lot {
    ${commonFields.lot}
  }
`

export const inventory = gql`
  fragment InventoryFields on Inventory {
    ${commonFields.inventory}
  }
`

/**
 * The siteplan fragment (SiteplanFields) depends on the the inventory, plan,
 * and elevation fragments.
 */
export const siteplanSVG = gql`
  fragment SiteplanSVGFields on Siteplan {
    svg {
      viewBox {
        x
        y
        width
        height
      }
      style
      frame {
        x
        y
        width
        height
      }
      shapes {
        tagName
        attributes {
          className
          dataName
          x
          y
          width
          height
          transform
          points
          d
        }
      }
    }
  }
`

export const siteplanInfo = gql`
  fragment SiteplanInfoFields on Siteplan {
    info(clientName: $clientName) {
      siteplanId
      thumb
      fontSize
      x
      y
      whiteLabel
      showsThumb
    }
  }
`

export const lots = gql`
  fragment SiteplanLotsFields on Siteplan {
    lots(clientName: $clientName) {
      id
      communityId
      dataName
      name
      salesStatus
      premium
      externalId
      address
      size
      cityName
      stateCode
      siteplanName(clientName: $clientName)
      siteplanDisplayName(clientName: $clientName)
      zip
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
    }
  }
`

export const lotLegend = gql`
  fragment LotLegendFields on Siteplan {
    lotLegend(clientName: $clientName) {
      id
      code
      name
      hex
    }
  }
`

export const siteplanHostpots = gql`
  fragment SiteplanHotspotsFields on Siteplan {
    hotspots {
      id
      siteplanId
      name
      x
      y
      width
      height
      description
      thumb
      assets {
        id
        listIndex
        src
        videoUrl
        description
      }
    }
  }
`
