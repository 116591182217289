import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import createTypography from '@material-ui/core/styles/createTypography'
import * as F from '@anewgo/functions'
import chroma from 'chroma-js'

import { withBuilderAppConfig, withClientCustomizations } from '../utils/hocs'
import { DEFAULT_PRIMARY_COLOR } from '../constants'
import { compose } from 'react-recompose'
import { useEffect, useState } from 'react'

const palette = createPalette('light')

let themeData = {
  primaryColor: null,
  secondaryColor: null,
  fontFamily: null,
  theme: null,
}

const getMemoizedTheme = ({ bannerColor, fontFamily, useFontsThroughoutApp }) => {
  if (themeData.bannerColor !== bannerColor || themeData.fontFamily !== fontFamily) {
    themeData.bannerColor = bannerColor
    themeData.fontFamily = fontFamily
    themeData.theme = createMuiTheme({
      palette: {
        primary: {
          light: chroma(DEFAULT_PRIMARY_COLOR).brighten(2).hex(),
          main: DEFAULT_PRIMARY_COLOR,
          dark: chroma(DEFAULT_PRIMARY_COLOR).darken().hex(),
          contrastText: '#fff',
        },
        bannerColor: bannerColor,
      },
      typography: createTypography(palette, {
        useNextVariants: true,
        fontFamily: useFontsThroughoutApp ? fontFamily : 'Gotham Book, sans-serif',
        body2: {
          fontFamily: useFontsThroughoutApp ? fontFamily : 'Merriweather, serif',
        },
      }),
      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 960,
          lg: 1280,
          xl: 1920,
          xxl: 2560,
          xxxl: 3360,
        },
      },
    })
  }
  return themeData.theme
}

export const ThemeProvider = compose(withClientCustomizations)(
  withBuilderAppConfig((props) => {
    const bgColorObj =
      props.clientCustomizations &&
      props.clientCustomizations.find((obj) => obj.name === 'bgColor')
    const bannerColor = bgColorObj ? `#${bgColorObj.value}` : null
    const fontsFromConfig = F.getPath('theme', 'font_family')(props.builderAppConfig)
    const useFontsThroughoutApp = F.getPath(
      'theme',
      'kiosk_full_custom_font'
    )(props.builderAppConfig)
    const bgColorObjId = bgColorObj && bgColorObj.id
    const [addedFonts, setAddedFonts] = useState([])
    useEffect(() => {
      if (!fontsFromConfig) return

      const fontsToAdd = fontsFromConfig.filter(
        ({ family }) => !addedFonts.some(F.propEquals('family', family))
      )
      const fontFacesToAdd = fontsToAdd.map(
        ({ family, src }) => new FontFace(family, `url(${src})`)
      )
      fontFacesToAdd.forEach((fontFace) => {
        document.fonts.add(fontFace)
        fontFace.load()
      })

      document.fonts.ready.then(() => {
        if (fontsToAdd.length === 0) return
        setAddedFonts([...addedFonts, fontsToAdd])
      })
    }, [fontsFromConfig, bgColorObjId])
    const fontFamily = fontsFromConfig
      ? fontsFromConfig.map(F.getProp('family')).join(', ')
      : 'Gotham Book, sans-serif'
    const theme = getMemoizedTheme({ bannerColor, fontFamily, useFontsThroughoutApp })
    return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
  })
)
