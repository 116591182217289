import {
  compose,
  withHandlers,
  fromRenderProps as recompose_fromRenderProps,
} from 'react-recompose'
import { connect } from 'react-redux'
import { matchPath, withRouter } from 'react-router'
import queryString from 'query-string'

import { graphql } from '@apollo/client/react/hoc'
import { getPath, pipe, propEquals } from '@anewgo/functions'
import { siteplans } from '@anewgo/utils'

import { VIEW_DESIGN_EXTERIOR, VIEW_DESCRIPTION } from '../constants'
import { prefetchLayers, graphqlProps } from '../utils'
import * as reducers from '../reducers'
import * as actions from '../actions'
import { BUILDER_APP_CONFIG, CLIENT, PLAN, LOT } from '../graphql'

const { inventoryRequiredSalesStatuses } = siteplans

const clientPath = '/client/:clientName'
const communityPath = clientPath + '/community/:communityName'
const planPath = communityPath + '/plan/:planName'
const inventoryPath = communityPath + '/inventory'

const getMatch = ({ path, history } = {}) => {
  return matchPath(history.location.pathname, { path })
}

export const withBuilderAppConfig = compose(
  withRouter,
  graphql(BUILDER_APP_CONFIG, {
    skip: ({ history }) => !getMatch({ history, path: clientPath }),
    options: ({ history }) => ({
      variables: {
        clientName: getMatch({ history, path: clientPath }).params.clientName,
      },
    }),
    props: graphqlProps('builderAppConfig', {
      optional: true,
    }),
  })
)

/**
 * Injects `ClientCustomizations` props into based on the wrapper
 * Launch all service and go to `http://localhost:9000/client/demo/community/Carries%20Reach/plan/Benson/print/brochure/?prospectId=Mark%20Christian%20Navalta%3Cnavalta3030@gmail.com%3E&homeId=ce8bccec0f29394c02aa435a75dab7fe&elevId=14550&loggedInAgentName=Mark%20Christian%20Navalta&loggedInAgentEmail=navalta3030@gmail.com&loggedInAgentPhone=9162368604`
 * Use reactdevtools to find the PlainBrochure component for an example within the props
 */
export const withClientCustomizations = compose(
  withRouter,
  graphql(BUILDER_APP_CONFIG, {
    skip: ({ history }) => !getMatch({ history, path: clientPath }),
    options: ({ history }) => ({
      variables: {
        clientName: getMatch({ history, path: clientPath }).params.clientName,
      },
    }),
    props: graphqlProps('clientCustomizations', {
      optional: true,
    }),
  })
)

export const withCommunity = (query, options) =>
  compose(
    withRouter,
    graphql(query, {
      skip: ({ history }) => !getMatch({ history, path: communityPath }),
      options: ({ history }) => {
        const match = getMatch({ history, path: communityPath })

        return {
          variables: {
            clientName: match.params.clientName,
            communityName: match.params.communityName,
          },
          ...options,
        }
      },
      props: graphqlProps('communityByName', {
        mapResultsToProps: (community) => ({ community }),
      }),
    })
  )

export const withSelectionLink = ({ propName } = {}) => {
  const mapStateToProps = (state) => ({
    isInActiveAdultMode: reducers.getAppConfig(state).mode === 'ACTIVE_ADULT',
  })

  const mapDispatchToProps = (dispatch) => ({
    restoreFavorite(selection) {
      dispatch(actions.selections.restoreFavorite(selection))
    },
    toggleMirror(mirror) {
      dispatch(actions.selections.toggleMirror(mirror))
    },
    setFloorplansView(floorplansView) {
      dispatch(actions.ui.setFloorplansView({ floorplansView }))
    },
  })

  return compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),

    withHandlers({
      [propName]: (ownProps) => (selection) => {
        const {
          isInActiveAdultMode,
          history,
          match,
          restoreFavorite,
          setFloorplansView,
          toggleMirror,
        } = ownProps
        const { clientName, masterCommunityName } = match.params
        let { community, plan, elevation, mirror, lot } = selection

        if (!getPath('id')(plan) && lot) {
          plan = getPath('inventory', 'plan')(lot)
          elevation = getPath('inventory', 'elevation')(lot)
        }

        // Restore selection as a favorite
        restoreFavorite({ ...selection, plan, elevation })
        toggleMirror(mirror)
        // Bail out if no elevation exists

        if (!elevation) return

        // Preset floorplans view

        const isInventorySelection = inventoryRequiredSalesStatuses.includes(
          getPath('lot', 'salesStatus')(selection)
        )

        if (isInventorySelection) {
          setFloorplansView(VIEW_DESCRIPTION)
        } else {
          const engineUrl = `${process.env.REACT_APP_HEMI_ENGINE}/exterior/client/${clientName}/nbr/${community.name}/plan/${plan.name}`
          prefetchLayers(engineUrl, elevation.id)

          setFloorplansView(VIEW_DESCRIPTION)
        }

        // Push to history
        let path = `/client/${clientName}`
        if (masterCommunityName) path += `/master/${masterCommunityName}`

        const inventoryId = getPath('inventory', 'id')(lot)
        if (isInventorySelection && inventoryId) {
          const planRoute = plan ? `&planId=${plan.id}` : ''
          const elevRoute = elevation ? `&elevationId=${elevation.id}` : ''
          path += `/community/${community.name}`
          path += isInActiveAdultMode
            ? `/floorplan/${plan.name}?elevationId=${elevation.id}`
            : `/floorplans?inventoryId=${inventoryId}${planRoute}${elevRoute}`
        } else {
          path += `/community/${community.name}`
          path += isInActiveAdultMode
            ? `/floorplan/${plan.name}?elevationId=${elevation.id}`
            : `/floorplans?planId=${plan.id}&elevationId=${elevation.id}`
        }

        history.push(path)
      },
    })
  )
}

/**
 * Injects `Client` props into based on the wrapper
 * Launch all service and go to `http://localhost:9000/client/demo/community/Carries%20Reach/plan/Benson/print/brochure/?prospectId=Mark%20Christian%20Navalta%3Cnavalta3030@gmail.com%3E&homeId=ce8bccec0f29394c02aa435a75dab7fe&elevId=14550&loggedInAgentName=Mark%20Christian%20Navalta&loggedInAgentEmail=navalta3030@gmail.com&loggedInAgentPhone=9162368604`
 * Use reactdevtools to find the PlainBrochure component for an example within the props
 */
export const withClient = compose(
  withRouter,
  graphql(CLIENT, {
    skip: ({ history }) => !getMatch({ history, path: clientPath }),
    options: ({ history }) => ({
      variables: {
        clientName: getMatch({ history, path: clientPath }).params.clientName,
      },
    }),
    props: graphqlProps('clientByName', {
      mapResultsToProps: (client) => {
        return { client }
      },
    }),
  })
)

/**
 * Injects `plan, elevation, and defaultElevation` props into based on the wrapper
 * Launch all service and go to `http://localhost:9000/client/demo/community/Carries%20Reach/plan/Benson/print/brochure/?prospectId=Mark%20Christian%20Navalta%3Cnavalta3030@gmail.com%3E&homeId=ce8bccec0f29394c02aa435a75dab7fe&elevId=14550&loggedInAgentName=Mark%20Christian%20Navalta&loggedInAgentEmail=navalta3030@gmail.com&loggedInAgentPhone=9162368604`
 * Use reactdevtools to find the PlainBrochure component for an example within the props
 */
export const withPlanElevation = compose(
  withRouter,
  graphql(PLAN, {
    skip: ({ history }) => !getMatch({ history, path: planPath }),
    options: ({ history }) => {
      const match = getMatch({ history, path: planPath })

      return {
        variables: {
          clientName: match.params.clientName,
          communityName: match.params.communityName,
          planName: match.params.planName,
        },
      }
    },
    props: graphqlProps('planByName', {
      mapResultsToProps: (plan, ownProps) => {
        const elevationId = pipe(queryString.parse, ({ elevId }) =>
          elevId === undefined ? undefined : parseInt(elevId, 10)
        )(ownProps.location.search)

        return {
          plan,
          elevation: elevationId && plan.elevations.find(propEquals('id', elevationId)),
          defaultElevation:
            plan.elevations.find(propEquals('id', plan.defaultElevationId)) ||
            plan.elevations[0],
        }
      },
    }),
  })
)

/**
 * Injects `lot, and Inventory` props into based on the wrapper
 * Launch all service and go to for example -
 * `http://localhost:9000/client/demo/community/Carries%20Reach/plan/Benson/print/brochure/?prospectId=Mark%20Christian%20Navalta%3Cnavalta3030@gmail.com%3E&homeId=ce8bccec0f29394c02aa435a75dab7fe&elevId=14550&loggedInAgentName=Mark%20Christian%20Navalta&loggedInAgentEmail=navalta3030@gmail.com&loggedInAgentPhone=9162368604`
 * Use reactdevtools to find the PlainBrochure component for an example within the props
 */
export const withLot = compose(
  withRouter,
  graphql(LOT, {
    skip: ({ location }) => {
      const inventoryId = pipe(queryString.parse, ({ inventoryId }) =>
        inventoryId === undefined ? undefined : parseInt(inventoryId, 10)
      )(location.search)
      const lotId = pipe(queryString.parse, ({ lotId }) =>
        lotId === undefined ? undefined : parseInt(lotId, 10)
      )(location.search)
      return !inventoryId && !lotId
    },
    options: ({ history, location }) => {
      const match =
        getMatch({ history, path: planPath }) ||
        getMatch({ history, path: inventoryPath })
      const inventoryId = pipe(queryString.parse, ({ inventoryId }) =>
        inventoryId === undefined ? undefined : parseInt(inventoryId, 10)
      )(location.search)
      const lotId = pipe(queryString.parse, ({ lotId }) =>
        lotId === undefined ? undefined : parseInt(lotId, 10)
      )(location.search)

      return {
        variables: {
          clientName: match.params.clientName,
          lotId,
          inventoryId,
        },
      }
    },
    props: graphqlProps('lot', {
      mapResultsToProps: (lot) => ({
        lot,
        inventory: lot.inventory,
      }),
    }),
  })
)

export const fromRenderProps = (RenderPropsComponent, options) => (Component) => {
  switch (typeof options) {
    case 'function':
      return recompose_fromRenderProps(RenderPropsComponent, options)(Component)
    case 'object':
      const { injectProps, mapRenderProps } = options

      return (props) => (
        <RenderPropsComponent {...(injectProps && injectProps(props))}>
          {(...args) => (
            <Component
              {...{
                ...props,
                ...mapRenderProps(props)(...args),
              }}
            />
          )}
        </RenderPropsComponent>
      )
    default:
      throw new Error(
        'Attempted to call fromRenderProps with an options argument that was neither a function nor an object',
        {
          RenderPropsComponent,
          Component,
          options,
        }
      )
  }
}
