export const plan = `
  id
  displayName
  name
  communityId
  defaultElevationId
  description
  bed
  bath
  cost
  size
  videoUrl
  interactiveInteriorIds(clientName: $clientName)
`

export const elevation = `
  id
  mirrorElevationId
  mirrorRolePrimary
  communityId
  planName
  planDisplayName
  planId
  caption
  thumb
  bed
  bath
  size
  cost
  defaultFloor
  description
  svgMirroring
  garagePosition
  defaultGaragePosition
`

export const elevationTags = `
  tags(clientName: $clientName) {
    categoryName
    tagName
  }
`

export const lot = `
  id
  communityId
  dataName
  name
  salesStatus
  premium
  externalId
  address
  size
  cityName
  stateCode
  zip
  postCode
  availableSchemes(clientName: $clientName) {
    id
    name
  }
`

export const inventory = `
  id
  lotId
  planId
  elevationId
  communityId
  price
  sqft
  beds
  baths
  features
  constructionStatus
  closingDate
  mlsId
  garageType
  garageCapacity
  floors
  schemeId
  photoFolder
  plan(clientName: $clientName) {
    id
    communityId
    name
    displayName
  }
  sgtData(clientName: $clientName) {
    id
    sgtVendorId
    sgtExternalId
    sgtData
  }
  appointmentsEnabled
  reserveHomeUrl
  garagePosition
`

export const story = `
  id
  communityId
  planId
  elevationId
  name
  fnum
  optional
`

export const floorplan = `
  id
  groupId
  name
  base
  optional
  standard
  cost
  size
  bed
  bath
  listIndex
  src
  src2
  labelSvg
  fnum
`

export const ranges = `
  bedRange(clientName: $clientName) {
    min
    max
  }
  bathRange(clientName: $clientName) {
    min
    max
  }
  costRange(clientName: $clientName) {
    min
    max
  }
  sizeRange(clientName: $clientName) {
    min
    max
  }
`
