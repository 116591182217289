import { gql } from '@apollo/client'
import * as fragments from './fragments'

// ############ Common fields ############
const optionFields = `
    id
    name
    cost
    hex
    interiorListElementId
    overlaySrc
    overlaySwatchSrc
    listIndex
`

const listElementsFields = `
  id
  defaultElementListOptionId
  parentInteriorListElementId
  cost
  name
  interiorId
  elementType
  listIndex
  overlaySwatchSrc
  interiorElement {
    id
    optionBlendMode
  }
  options {
    ${optionFields}
  }
`

const UPSERT_PROSPECT = gql`
  mutation UpsertProspect($clientName: String!, $input: ProspectInput!) {
    upsertProspect(clientName: $clientName, input: $input) {
      id
      email
      name
      firstName
      lastName
      picture
      source
      phone
      preferredContactMethods
      brochures {
        url
        creationDate
      }
      registrationDate
      consultantName
      consultantEmail
      consultantPhone
      communityId
      communityExternalId
      planId
      elevationId
      schemeId
      lotId
      lotExternalId
      inventoryId
      prospectEmailExists
      onlineReservations {
        id
        prospectReservationId
        favoriteId
        reservationStatus
        signatureDate
        secondaryBuyerReservations {
          signatureDate
        }
      }
      favorites {
        id
        clientName
        communityId
        planId
        elevationId
        lotId
        schemeId
        colorSelections
        fpOptSelections
        garagePosition
        community(clientName: $clientName) {
          id
          name
          address
          caption
          description
          logo
          thumb
          pricing
          agents(clientName: $clientName) {
            id
            firstName
            lastName
            email
            phone
            picture
          }
        }
        plan(clientName: $clientName) {
          id
          communityId
          name
          bed
          bath
          cost
          defaultElevationId
          size
          description
          exteriorAssets(clientName: $clientName) {
            src
            listIndex
            videoSrc
          }
        }
        elevation(clientName: $clientName) {
          id
          bed
          bath
          communityId
          cost
          planId
          planName
          caption
          thumb
          size
          svgMirroring
          mirrorElevationId
          description
          materialPalettes(clientName: $clientName) {
            materialId
            communityId
            planId
            elevationId
            name
          }
        }
        lot(clientName: $clientName) {
          id
          size
          name
          dataName
          address
          salesStatus
          premium
          siteplanName(clientName: $clientName)
          siteplanDisplayName(clientName: $clientName)
          siteplanInfo {
            lotId
            x
            y
          }
          excludedPlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            elevationCaption
          }
          availablePlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            elevationCaption
          }
          availableSchemes(clientName: $clientName) {
            id
            name
          }
          inventory(clientName: $clientName) {
            id
            beds
            baths
            features
            sqft
            price
            planId
            plan(clientName: $clientName) {
              id
              communityId
              name
              defaultElevationId
              cost
              description
            }
            elevation(clientName: $clientName) {
              id
              mirrorElevationId
              communityId
              planId
              planName
              caption
              bed
              base
              bath
              size
              cost
              thumb
              description
            }
            elevationId
            photoFolder
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
            exteriorConfiguration(clientName: $clientName) {
              inventoryId
              scheme {
                id
                name
                materials(clientName: $clientName) {
                  id
                  materialId
                  materialCategory
                  material
                  name
                  hex
                  type
                  swatch
                  masonryLib
                  colorId
                  brickId
                  stoneId
                  customOverlayId
                }
              }
              paletteElementSelections {
                inventoryId
                elementId
                paletteId
                paletteSelection {
                  id
                  name
                  hex
                  type
                  swatch
                  masonryLib
                }
              }
            }
          }
        }
        scheme(clientName: $clientName) {
          id
          name
          materials(clientName: $clientName) {
            id
            materialId
            materialCategory
            material
            name
            hex
            type
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
            paletteIds(clientName: $clientName)
          }
        }
        inventory(clientName: $clientName) {
          id
          lotId
          planId
          elevationId
          communityId
          mlsId
          sqft
          beds
          baths
          floors
          schemeId
          closingDate
          photoFolder
          price
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
          sgtData(clientName: $clientName) {
            id
            sgtVendorId
            sgtData
          }
          appointmentsEnabled
          reserveHomeUrl
        }
      }
    }
  }
`

export const AUTHENTICATE_API_KEY = gql`
  query AuthenticateApiKey($apiKey: String!) {
    authenticateApiKey(apiKey: $apiKey)
  }
`

export const REGISTER_PROSPECT = gql`
  mutation RegisterProspect($clientName: String!, $input: ProspectInput!) {
    addProspect(clientName: $clientName, input: $input) {
      id
    }
  }
`

export const SEND_REGISTRATION_EMAIL = gql`
  mutation SendProspectRegistrationEmail(
    $clientName: String!
    $prospectId: Int!
    $input: ProspectInput
  ) {
    sendProspectRegistrationEmail(
      clientName: $clientName
      prospectId: $prospectId
      source: "camber"
      input: $input
    )
  }
`

export const CLIENT = gql`
  query GetClient($clientName: String!) {
    clientByName(clientName: $clientName) {
      id
      name
      altName
      directoryName
      disclaimer
      trackingId
      logo
      website
      sgtVendors(clientName: $clientName) {
        id
        icon
        logo
        name
        extendedData
      }
    }
  }
`

const GET_CLIENT = gql`
  query GetClient($clientName: String!) {
    clientByName(clientName: $clientName) {
      id
      name
      altName
      directoryName
      trackingId
      logo
      address1
      address2
      disclaimer
    }
  }
`

const COMMUNITY_WITH_PM_FLOORPLANS = gql`
  query CommunityWithPmFloorplans($clientName: String!, $communityId: Int!) {
    community(clientName: $clientName, communityId: $communityId) {
      id
      name
      pmFloorplans(clientName: $clientName) {
        id
        name
        size
        cost
        bed
        bath
        videoUrl
        primarySrc
        secondarySrc
      }
    }
  }
`

const AGENTS_BY_CLIENT_QUERY = gql`
  query agentsByClient($clientName: String!, $communityId: Int, $include: Boolean!) {
    agentsByClient(clientName: $clientName, communityId: $communityId) {
      id
      firstName
      lastName
      email
      phone
      picture
      listIndex @include(if: $include)
      communityId @include(if: $include)
    }
  }
`
const GET_STD_FEATURE_ASSIGNMENTS = gql`
  query GetStandardFeatureAssignments($clientName: String!, $communityId: Int!) {
    stdFeatureAssignments(clientName: $clientName, communityId: $communityId) {
      categoryId
      communityId
      featureId
      name
      categoryName
    }
  }
`

const GET_COMMUNITY_BY_NAME = gql`
  query GetCommunityByName($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      id
      name
      thumb
      caption
      colormtd
      description
      logo
      logoWhite
      mapMarker
      longitude
      latitude
      address
      communityType
      pricing
      salesCenterActive
      agents(clientName: $clientName) {
        id
        email
        phone
        firstName
        lastName
        picture
      }
      cityLocation(clientName: $clientName) {
        name
        customName
        region {
          __typename
          ... on USCityRegionInfo {
            stateCode
            zipCode
          }
          ... on CANCityRegionInfo {
            provinceCode
            postCode
          }
        }
      }
      colorTheme(clientName: $clientName) {
        id
        name
        entries(clientName: $clientName) {
          id
          hex
          name
          rank
        }
      }
      galleries(clientName: $clientName) {
        id
        name
        photos(clientName: $clientName) {
          id
          caption
          src
          listIndex
        }
      }
      highlights(clientName: $clientName) {
        id
        name
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
      }
      mainPhotos(clientName: $clientName) {
        id
        src
        listIndex
      }
      overviewPhotos(clientName: $clientName) {
        id
        src
        listIndex
      }
      poiCategories(clientName: $clientName) {
        id
        name
        icon
        pois(clientName: $clientName) {
          id
          address
          name
          latitude
          longitude
        }
      }
      lots(clientName: $clientName, quickMoveInOnly: true, activeSiteplanOnly: true) {
        id
        size
        name
        dataName
        address
        salesStatus
        premium
        siteplanName(clientName: $clientName)
        siteplanDisplayName(clientName: $clientName)
        siteplanInfo {
          lotId
          siteplanId
          x
          y
        }
        excludedPlanElevations(clientName: $clientName) {
          planId
          elevationId
          planName
          elevationCaption
        }
        availablePlanElevations(clientName: $clientName) {
          planId
          elevationId
          planName
          elevationCaption
        }
        availableSchemes(clientName: $clientName) {
          id
          name
        }
        inventory(clientName: $clientName) {
          id
          beds
          baths
          features
          sqft
          price
          planId
          closingDate
          constructionStatus
          virTourUrl
          garagePosition
          includedFloorplans(clientName: $clientName) {
            id
            base
            bath
            bed
            cost
            fnum
            labelSvg
            lastUpdateTime
            listIndex
            name
            notes
            optional
            renderIndex
            size
            src
            src2
            standard
          }
          plan(clientName: $clientName) {
            id
            communityId
            name
            defaultElevationId
            description
          }
          elevation(clientName: $clientName) {
            id
            mirrorElevationId
            communityId
            planId
            planName
            caption
            bed
            base
            bath
            size
            cost
            thumb
            svgMirroring
            stories(clientName: $clientName) {
              id
              fnum
            }
          }
          elevationId
          photoFolder
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
          exteriorConfiguration(clientName: $clientName) {
            inventoryId
            scheme {
              id
              name
              materials(clientName: $clientName) {
                id
                materialId
                materialCategory
                material
                name
                hex
                type
                swatch
                masonryLib
              }
            }
            paletteElementSelections {
              inventoryId
              elementId
              paletteId
              paletteSelection {
                id
                name
                hex
                type
                swatch
                masonryLib
              }
            }
          }
        }
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        lotMetric
      }
      inventories(clientName: $clientName) {
        id
        mlsId
        mlsStatus
        sqft
        beds
        price
        baths
      }
      plans(clientName: $clientName) {
        id
        communityId
        name
        defaultElevationId
        displayName
        cost
        description
        videoUrl
        exteriorAssets(clientName: $clientName) {
          src
          listIndex
          videoSrc
        }
        interiorPhotos(clientName: $clientName) {
          src
          listIndex
        }
        bedRange(clientName: $clientName) {
          min
          max
        }
        bathRange(clientName: $clientName) {
          min
          max
        }
        costRange(clientName: $clientName) {
          min
          max
        }
        sizeRange(clientName: $clientName) {
          min
          max
        }
        elevations(clientName: $clientName, active: true) {
          id
          mirrorElevationId
          mirrorRolePrimary
          communityId
          planId
          planName
          caption
          bed
          base
          bath
          size
          cost
          thumb
          svgMirroring
          garagePosition
          defaultGaragePosition
          description
          collection(clientName: $clientName) {
            id
            communityId
            name
            siteplan(clientName: $clientName, active: true) {
              id
              name
              displayName
            }
          }
          tags(clientName: $clientName) {
            categoryName
            tagName
          }
          bedRange(clientName: $clientName) {
            min
            max
          }
          bathRange(clientName: $clientName) {
            min
            max
          }
          costRange(clientName: $clientName) {
            min
            max
          }
          sizeRange(clientName: $clientName) {
            min
            max
          }
        }
      }
      imgs(clientName: $clientName) {
        id
        src
      }
      stdFeatureCategories(clientName: $clientName) {
        id
        name
        features(clientName: $clientName, communityName: $communityName) {
          id
          name
        }
      }
      schemes(clientName: $clientName) {
        id
        name
        materials(clientName: $clientName) {
          id
          materialId
          materialCategory
          material
          name
          hex
          swatch
          masonryLib
          colorId
          brickId
          stoneId
          customOverlayId
        }
      }
    }
  }
`

const GET_ELEVATION_COLORS = gql`
  query GetElevationLayers(
    $clientName: String!
    $elevationId: Int!
    $planId: Int!
    $communityId: Int
  ) {
    elevation(
      clientName: $clientName
      elevationId: $elevationId
      planId: $planId
      communityId: $communityId
    ) {
      id
      planId
      communityId
      base
      mirrorElevationId
      thumb
      svgMirroring
      garagePosition
      floorplans(clientName: $clientName, planId: $planId, communityId: $communityId) {
        id
        name
        fnum
        optional
      }
      stories(clientName: $clientName, planId: $planId, communityId: $communityId) {
        id
        communityId
        planId
        elevationId
        name
        fnum
        optional
        floorplans(clientName: $clientName) {
          id
          name
          optional
          fnum
        }
      }
      layers(clientName: $clientName) {
        materialId
        materialCategory
        name
        src
      }
      materialPalettes(
        clientName: $clientName
        planId: $planId
        communityId: $communityId
      ) {
        communityId
        planId
        elevationId
        materialId
        materialCategory
        name
        palettes {
          id
          name
          selections(clientName: $clientName) {
            id
            name
            hex
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
          }
        }
      }
      schemes(clientName: $clientName, planId: $planId, communityId: $communityId) {
        id
        name
        cost
        materials(clientName: $clientName) {
          id
          materialId
          materialCategory
          material
          name
          hex
          swatch
          masonryLib
          colorId
          brickId
          stoneId
          customOverlayId
          paletteIds(clientName: $clientName, communityId: $communityId)
        }
      }
    }
  }
`

const GET_FLOORPLANS_BY_ELEVATION_ID = gql`
  query GetFloorplansByElevationId(
    $clientName: String!
    $communityId: Int!
    $planId: Int!
    $elevationId: Int!
  ) {
    floorplansByElevationId(
      clientName: $clientName
      communityId: $communityId
      planId: $planId
      elevationId: $elevationId
    ) {
      planId
      communityId
      elevationId
      defaultFloor
      stories {
        id
        communityId
        planId
        elevationId
        name
        fnum
        optional
        floorplans(clientName: $clientName) {
          id
          name
          base
          optional
          standard
          cost
          size
          bed
          bath
          listIndex
          src
          src2
          labelSvg
          fnum
        }
        fpGroups(clientName: $clientName) {
          id
          name
          groupType
          primary(clientName: $clientName) {
            id
            name
          }
          members(clientName: $clientName) {
            id
            name
            groupId
            fnum
            alternate(clientName: $clientName) {
              id
              cost
              size
              bed
              bath
              src
              src2
              labelSvg
            }
          }
        }
      }
    }
  }
`

export const FLOORPLANS_BY_ELEVATION = gql`
  query GetFloorplansByElevationId(
    $clientName: String!
    $planId: Int!
    $elevationId: Int!
    $communityId: Int!
  ) {
    elevation(
      clientName: $clientName
      planId: $planId
      elevationId: $elevationId
      communityId: $communityId
    ) {
      id
      mirrorElevationId
      mirrorRolePrimary
      defaultFloor
      communityId
      planId
      planName
      caption
      thumb
      bed
      bath
      size
      cost
      base
      svgMirroring
      garagePosition
      defaultGaragePosition
      description
      floorplans(clientName: $clientName, planId: $planId, communityId: $communityId) {
        id
        name
        standard
        src
        src2
        labelSvg
        fnum
        base
        optional
        cost
        size
        bed
        bath
        notes
        listIndex
        renderIndex
        lastUpdateTime
      }
      fpGroups(clientName: $clientName, planId: $planId, communityId: $communityId) {
        id
        groupType
        name
        autoselectLinkedChildParents
        primary(clientName: $clientName) {
          id
        }
        members(clientName: $clientName) {
          id
          groupId
          alternate(clientName: $clientName) {
            id
            name
            src
            src2
            labelSvg
            cost
            size
            bed
            bath
          }
        }
      }
      stories(clientName: $clientName, planId: $planId, communityId: $communityId) {
        id
        communityId
        planId
        elevationId
        name
        fnum
        optional
        floorplans(clientName: $clientName) {
          id
          name
          base
          optional
          standard
          cost
          size
          bed
          bath
          listIndex
          src
          src2
          labelSvg
          fnum
        }
        fpGroups(clientName: $clientName) {
          id
          name
          groupType
          primary(clientName: $clientName) {
            id
            name
          }
          members(clientName: $clientName) {
            id
            name
            groupId
            fnum
            alternate(clientName: $clientName) {
              id
              cost
              size
              bed
              bath
              src
              src2
              labelSvg
            }
          }
        }
      }
      tags(clientName: $clientName) {
        categoryName
        tagName
      }
    }
  }
`

const GET_LOT = gql`
  query GetLot($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      id
      size
      name
      dataName
      address
      salesStatus
      premium
      siteplanName(clientName: $clientName)
      siteplanDisplayName(clientName: $clientName)
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
      excludedPlanElevations(clientName: $clientName) {
        planId
        elevationId
        planName
        elevationCaption
      }
      availablePlanElevations(clientName: $clientName) {
        planId
        elevationId
        planName
        elevationCaption
      }
      availableSchemes(clientName: $clientName) {
        id
        name
      }
      inventory(clientName: $clientName) {
        id
        beds
        baths
        features
        sqft
        price
        planId
        plan(clientName: $clientName) {
          id
          communityId
          name
          defaultElevationId
          cost
        }
        elevation(clientName: $clientName) {
          id
          mirrorElevationId
          communityId
          planId
          planName
          caption
          bed
          base
          bath
          size
          cost
          thumb
        }
        elevationId
        photoFolder
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
        exteriorConfiguration(clientName: $clientName) {
          inventoryId
          scheme {
            id
            name
            materials(clientName: $clientName) {
              id
              materialId
              materialCategory
              material
              name
              hex
              type
              swatch
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
            }
          }
          paletteElementSelections {
            inventoryId
            elementId
            paletteId
            paletteSelection {
              id
              name
              hex
              type
              swatch
              masonryLib
            }
          }
        }
      }
    }
  }
`

const GET_LOT_SITEPLAN_INFO = gql`
  query GetLot($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      id
      size
      name
      dataName
      address
      salesStatus
      premium
      siteplanName(clientName: $clientName)
      siteplanDisplayName(clientName: $clientName)
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
      availableSchemes(clientName: $clientName) {
        id
        name
      }
    }
  }
`

const GET_MASTER_COMMUNITY_BY_NAME = gql`
  query GetMasterCommunityByName($clientName: String!, $name: String!) {
    masterCommunityByName(clientName: $clientName, name: $name) {
      id
      name
      caption
      caption2
      logo
      mapMarker
      mainImage
      poiCategories(clientName: $clientName) {
        id
        name
        icon
        pois(clientName: $clientName) {
          id
          address
          name
          latitude
          longitude
        }
      }
      colorTheme(clientName: $clientName) {
        id
        name
        entries(clientName: $clientName) {
          id
          hex
          name
          rank
        }
      }
      communities(clientName: $clientName) {
        id
        name
        latitude
        longitude
        logo
        logoWhite
        mapMarker
        primarySiteplan(clientName: $clientName, active: true) {
          id
          ...SiteplanInfoFields
        }
      }
      siteplan(clientName: $clientName, active: true) {
        id
        master
        src
        ...SiteplanSVGFields
      }
    }
  }
  ${fragments.siteplanSVG}
  ${fragments.siteplanInfo}
`

const BUILDER_APP_CONFIG = gql`
  query GetBuilderAppConfig($clientName: String!) {
    builderAppConfig(clientName: $clientName)
    clientCustomizations(clientName: $clientName) {
      id
      name
      value
    }
  }
`

const GET_SITEPLAN_BY_COMMUNITY_ID = gql`
  query GetActiveSiteplanByCommunityId($clientName: String!, $communityId: Int!) {
    activeSiteplanByCommunityId(
      clientName: $clientName
      communityId: $communityId
      master: false
    ) {
      ...SiteplanFields
    }
  }
  ${fragments.siteplan}
`

const GET_SITEPLAN_BY_ID = gql`
  query GetSiteplanById($clientName: String!, $id: Int!) {
    siteplanById(clientName: $clientName, id: $id) {
      ...SiteplanFields
    }
  }
  ${fragments.siteplan}
`

const GET_SITEPLAN_LITE_BROCHURE_BY_COMMUNITY_ID = gql`
  query GetSiteplanLiteBrochureByCommunityId($clientName: String!, $communityId: Int!) {
    activeSiteplanByCommunityId(
      clientName: $clientName
      communityId: $communityId
      master: false
    ) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      ...SiteplanSVGFields
      ...LotLegendFields
    }
  }
  ${fragments.siteplanSVG}
  ${fragments.lotLegend}
`

const GET_LOT_SITEPLAN_BY_ID = gql`
  query GetLotSiteplanById($clientName: String!, $id: Int!) {
    siteplanById(clientName: $clientName, id: $id) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      ...SiteplanSVGFields
      ...LotLegendFields
    }
  }
  ${fragments.siteplanSVG}
  ${fragments.lotLegend}
`

const ADD_HOME_SELECTION = gql`
  mutation AddHomeSelection($clientName: String!, $input: HomeSelectionInput!) {
    addHomeSelection(clientName: $clientName, input: $input)
  }
`
const ADD_AGENT_PROSPECT = gql`
  mutation addAgentProspect(
    $prospectEmail: String!
    $agent: UpsertAgentInput!
    $clientName: String!
    $source: String!
  ) {
    addAgentProspect(
      prospectEmail: $prospectEmail
      agent: $agent
      clientName: $clientName
      source: $source
    )
  }
`

const HOME_SELECTION = gql`
  query GetHomeSelection(
    $clientName: String!
    $prospectIdentifier: String
    $homeIdentifier: String
    $communityName: String!
    $planName: String
    $email: String
    $elevationId: Int
  ) {
    getHomeSelection(
      clientName: $clientName
      prospectIdentifier: $prospectIdentifier
      homeIdentifier: $homeIdentifier
      communityName: $communityName
      planName: $planName
      email: $email
      elevationId: $elevationId
    ) {
      json
      interiors {
        id
        name
        baseImageSrc
        disclaimer
        interiorAssignments {
          id
          interiorId
          communityId
          planId
          elevationId
        }
        elementTrees {
          ${listElementsFields}
          children {
            ${listElementsFields}
            children {
              ${listElementsFields}
              children {
                ${listElementsFields}
              }
            }
          }
        }
      }
      prospect {
        id
        email
        name
        firstName
        lastName
        phone
        picture
      }
      mirrorElevation {
        id
        planId
        caption
        communityId
        base
        thumb
        bed
        bath
        garagePosition
        size
        cost
        layers(clientName: $clientName) {
          materialId
          name
          materialCategory
          src
        }
        materialPalettes(clientName: $clientName) {
          communityId
          planId
          elevationId
          materialId
          materialCategory
          name
          palettes {
            id
            name
            elevationId
            materialId
            selections(clientName: $clientName) {
              id
              name
              hex
              swatch
              type
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
            }
          }
        }
        schemes(clientName: $clientName) {
          id
          name
          cost
          materials(clientName: $clientName) {
            id
            materialId
            materialCategory
            material
            name
            hex
            type
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
            paletteIds(clientName: $clientName)
          }
        }
      }
      elevationColors {
        id
        planId
        caption
        communityId
        base
        thumb
        bed
        bath
        garagePosition
        size
        cost
        layers(clientName: $clientName) {
          materialId
          name
          materialCategory
          src
        }
        materialPalettes(clientName: $clientName) {
          communityId
          planId
          elevationId
          materialId
          materialCategory
          name
          palettes {
            id
            name
            elevationId
            materialId
            selections(clientName: $clientName) {
              id
              name
              hex
              swatch
              type
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
            }
          }
        }
        schemes(clientName: $clientName) {
          id
          name
          cost
          materials(clientName: $clientName) {
            id
            materialId
            materialCategory
            material
            name
            hex
            type
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
            paletteIds(clientName: $clientName)
          }
        }
      }
      siteplan {
        id
        name
        displayName
        lotFontSize
        lotMetric
        lotWidth
        lotHeight
        master
        src
        unreleasedLotsHandle
        showUnreleasedLotNumber
        ...SiteplanSVGFields
        lotLegend(clientName: $clientName) {
          id
          code
          name
          hex
        }
        hotspots {
          id
          siteplanId
          name
          x
          y
          description
          thumb
          assets {
            id
            listIndex
            src
            description
          }
        }
      }
      client {
        id
        name
        altName
        directoryName
        disclaimer
        trackingId
        logo
        website
      }
      plan {
        ...PlanFields
        exteriorAssets(clientName: $clientName) {
          src
          listIndex
          videoSrc
        }
        interiorPhotos(clientName: $clientName) {
          src
          listIndex
        }
      }
      floorplans
      {
        planId
        communityId
        elevationId
        defaultFloor
        stories {
          id
          communityId
          planId
          elevationId
          name
          fnum
          optional
          floorplans(clientName: $clientName) {
            id
            name
            standard
            src
            src2
            labelSvg
            fnum
            base
            optional
            cost
            size
            bed
            bath
            notes
            listIndex
            renderIndex
            lastUpdateTime
          }
          fpGroups(clientName: $clientName) {
            id
            name
            groupType
            primary(clientName: $clientName) {
              id
              groupId
              standard
              name
            }
            members(clientName: $clientName) {
              id
              name
              groupId
              standard
              fnum
              alternate(clientName: $clientName) {
                id
                src
                src2
                labelSvg
                cost
                size
                bed
                bath
              }
            }
          }
        }
      }
      community {
        ...CommunityFields
        siteplans(clientName: $clientName, active: true) {
          id
          communityId
        }
        primarySiteplan(clientName: $clientName, active: true) {
          id
          lotMetric
          src
          geoInfo(clientName: $clientName) {
            id
          }
          lotLegend(clientName: $clientName) {
            id
            code
            name
            hex
          }
        }
        stdFeatureCategories(clientName: $clientName) {
          id
          name
          features(clientName: $clientName, communityName: $communityName) {
            id
            name
          }
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.plan}
  ${fragments.siteplanSVG}
`

const GET_SITEPLAN_LITE_BY_ID = gql`
  query GetSiteplanLiteById($clientName: String!, $id: Int!) {
    siteplanById(clientName: $clientName, id: $id) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      ...SiteplanSVGFields
      ...LotLegendFields
      ...SiteplanHotspotsFields
      ...SiteplanLotsFields
    }
  }
  ${fragments.siteplanSVG}
  ${fragments.lots}
  ${fragments.siteplanHostpots}
  ${fragments.lotLegend}
`

const GET_SITEPLAN_LITE_BY_COMMUNITY_ID = gql`
  query GetSiteplanLiteByCommunityId($clientName: String!, $communityId: Int!) {
    activeSiteplanByCommunityId(
      clientName: $clientName
      communityId: $communityId
      master: false
    ) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      subSiteplans(clientName: $clientName, active: true) {
        id
        name
        displayName
        ...SiteplanInfoFields
        lots(clientName: $clientName) {
          id
        }
      }
      ...SiteplanSVGFields
      ...LotLegendFields
      ...SiteplanHotspotsFields
      ...SiteplanLotsFields
    }
  }
  ${fragments.siteplanSVG}
  ${fragments.lots}
  ${fragments.siteplanHostpots}
  ${fragments.lotLegend}
  ${fragments.siteplanInfo}
`

const GET_SALES_APP_CONFIG = gql`
  query GetSalesAppConfig($clientName: String!, $communityId: Int!) {
    communitySalesAppConfig(clientName: $clientName, communityId: $communityId)
  }
`

const GET_MASTER_SALES_APP_CONFIG = gql`
  query GetMasterSalesAppConfig($clientName: String!, $masterCommunityId: Int!) {
    masterCommunitySalesAppConfig(
      clientName: $clientName
      masterCommunityId: $masterCommunityId
    )
  }
`

const INVENTORY_CONSTRUCTION_STATUSES = gql`
  query GetInventoryConstructionStatuses {
    __type(name: "InventoryConstructionStatus") {
      name
      enumValues {
        name
      }
    }
  }
`

const ADD_PROSPECT_FAVORITES = gql`
  mutation AddPropsectFavorites(
    $clientName: String!
    $email: String!
    $favorites: [ProspectFavoriteInput!]!
  ) {
    addFavoritesForProspect(clientName: $clientName, email: $email, favorites: $favorites)
  }
`

const UPDATE_PROSPECT_FAVORITES = gql`
  mutation UpdateProspectFavorites(
    $clientName: String!
    $input: [ProspectFavoriteInput!]!
  ) {
    updateFavoritesForProspect(clientName: $clientName, input: $input)
  }
`

const DELETE_PROSPECT_FAVORITE_BY_EMAIL = gql`
  mutation DeleteProspectFavoriteByEmail(
    $clientName: String!
    $id: Int!
    $prospectEmail: String!
  ) {
    deleteFavoriteForProspectByEmail(
      clientName: $clientName
      id: $id
      prospectEmail: $prospectEmail
    )
  }
`

const REPLACE_PROSPECT_FAVORITES = gql`
  mutation ReplaceProspectFavorites(
    $clientName: String!
    $email: String!
    $favorites: [ProspectFavoriteInput!]!
  ) {
    replaceFavoritesForProspect(
      clientName: $clientName
      email: $email
      favorites: $favorites
    ) {
      id
      clientName
      communityId
      planId
      elevationId
      lotId
      schemeId
      colorSelections
      fpOptSelections
      garagePosition
      community(clientName: $clientName) {
        id
        name
        address
        caption
        description
        logo
        thumb
        pricing
        agents(clientName: $clientName) {
          id
          firstName
          lastName
          email
          phone
          picture
        }
      }
      plan(clientName: $clientName) {
        id
        communityId
        name
        bed
        bath
        cost
        defaultElevationId
        size
        description
        exteriorAssets(clientName: $clientName) {
          src
          listIndex
          videoSrc
        }
      }
      elevation(clientName: $clientName) {
        id
        bed
        bath
        communityId
        cost
        planId
        planName
        caption
        thumb
        size
        svgMirroring
        mirrorElevationId
        description
        materialPalettes(clientName: $clientName) {
          materialId
          communityId
          planId
          elevationId
          name
        }
      }
      lot(clientName: $clientName) {
        id
        name
        salesStatus
        premium
        address
        dataName
        size
        cityName
        stateCode
        availableSchemes(clientName: $clientName) {
          id
          name
          internalName
          cost
        }
        inventory(clientName: $clientName) {
          id
          lotId
          planId
          elevationId
          communityId
          mlsId
          sqft
          beds
          baths
          floors
          schemeId
          closingDate
          photoFolder
          price
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
          sgtData(clientName: $clientName) {
            id
            sgtVendorId
            sgtData
          }
          appointmentsEnabled
          reserveHomeUrl
        }
      }
      scheme(clientName: $clientName) {
        id
        name
        materials(clientName: $clientName) {
          id
          materialId
          materialCategory
          material
          name
          hex
          type
          swatch
          masonryLib
          colorId
          brickId
          stoneId
          customOverlayId
          paletteIds(clientName: $clientName)
        }
      }
      inventory(clientName: $clientName) {
        id
        lotId
        planId
        elevationId
        communityId
        mlsId
        sqft
        beds
        baths
        floors
        schemeId
        closingDate
        photoFolder
        price
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
        sgtData(clientName: $clientName) {
          id
          sgtVendorId
          sgtData
        }
        appointmentsEnabled
        reserveHomeUrl
      }
    }
  }
`

const GET_PROSPECT_WITH_FAVORITES_BY_EMAIL = gql`
  query GetProspectByEmail($clientName: String!, $email: String!) {
    prospectByEmail(clientName: $clientName, email: $email) {
      id
      email
      name
      firstName
      lastName
      phone
      preferredContactMethods
      receivedFavorites {
        sentByProspectName
        sentByProspectEmail
        sentByProspectId
        receivedByProspectName
        receivedByProspectEmail
        clientId
        clientAltName
      }
      favorites {
        id
        clientName
        communityId
        planId
        elevationId
        lotId
        schemeId
        colorSelections
        fpOptSelections
        garagePosition
        community(clientName: $clientName) {
          id
          name
          address
          caption
          description
          logo
          thumb
          pricing
          agents(clientName: $clientName) {
            id
            firstName
            lastName
            email
            phone
            picture
          }
        }
        plan(clientName: $clientName) {
          id
          communityId
          name
          bed
          bath
          cost
          defaultElevationId
          size
          description
          exteriorAssets(clientName: $clientName) {
            src
            listIndex
            videoSrc
          }
        }
        elevation(clientName: $clientName) {
          id
          bed
          bath
          communityId
          cost
          planId
          planName
          caption
          thumb
          size
          description
          svgMirroring
          mirrorElevationId
          materialPalettes(clientName: $clientName) {
            materialId
            communityId
            planId
            elevationId
            name
          }
        }
        lot(clientName: $clientName) {
          id
          name
          salesStatus
          premium
          address
          dataName
          size
          cityName
          stateCode
          availableSchemes(clientName: $clientName) {
            id
            name
            internalName
            cost
          }
          inventory(clientName: $clientName) {
            id
            lotId
            planId
            elevationId
            communityId
            mlsId
            sqft
            beds
            baths
            floors
            schemeId
            closingDate
            photoFolder
            price
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
            sgtData(clientName: $clientName) {
              id
              sgtVendorId
              sgtData
            }
            appointmentsEnabled
            reserveHomeUrl
          }
        }
        scheme(clientName: $clientName) {
          id
          name
          materials(clientName: $clientName) {
            id
            materialId
            materialCategory
            material
            name
            hex
            type
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
            paletteIds(clientName: $clientName)
          }
        }
        inventory(clientName: $clientName) {
          id
          lotId
          planId
          elevationId
          communityId
          mlsId
          sqft
          beds
          baths
          floors
          schemeId
          closingDate
          photoFolder
          price
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
          sgtData(clientName: $clientName) {
            id
            sgtVendorId
            sgtData
          }
          appointmentsEnabled
          reserveHomeUrl
        }
      }
    }
  }
`

export const COMMUNITY_PLANS = gql`
  query GetCommunityByNameWithPlans($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      id
      name
      pricing
      agents(clientName: $clientName) {
        id
        email
        phone
        firstName
        lastName
      }
      plans(clientName: $clientName) {
        ...PlanFields
        elevations(clientName: $clientName, active: true) {
          ...ElevationFields
        }
      }
    }
  }
  ${fragments.plan}
  ${fragments.elevation}
`

const PLAN = gql`
  query GetPlanByName(
    $clientName: String!
    $communityName: String!
    $planName: String!
    $active: Boolean
  ) {
    planByName(
      clientName: $clientName
      communityName: $communityName
      planName: $planName
    ) {
      ...PlanFields
      exteriorAssets(clientName: $clientName) {
        src
        listIndex
        videoSrc
      }
      interiorPhotos(clientName: $clientName) {
        src
        listIndex
      }
      elevations(clientName: $clientName, active: $active) {
        ...ElevationFields
        collection(clientName: $clientName) {
          id
          communityId
          name
          siteplan(clientName: $clientName, active: true) {
            id
            name
            displayName
          }
        }
        communities(
          clientName: $clientName
          excludeDefaultCommunity: true
          active: true
        ) {
          ...CommunityFields
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.plan}
  ${fragments.elevation}
`

const LOT = gql`
  query GetLotWithInventory($clientName: String!, $lotId: Int, $inventoryId: Int) {
    lot(clientName: $clientName, lotId: $lotId, inventoryId: $inventoryId) {
      ...LotFields
      inventory(clientName: $clientName) {
        ...InventoryFields
        virTourUrl
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
        mlsPhotos
        includedFloorplans(clientName: $clientName) {
          id
          groupId
          standard
          name
        }
        plan(clientName: $clientName) {
          ...PlanFields
        }
        elevation(clientName: $clientName) {
          ...ElevationFields
        }
      }
    }
  }
  ${fragments.plan}
  ${fragments.elevation}
  ${fragments.lot}
  ${fragments.inventory}
`

const COMMUNITY_LITE = gql`
  query GetCommunityByNameLite($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      ...CommunityFields
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        lotMetric
      }
    }
  }
  ${fragments.community}
`

const PROSPECT_BROCHURE_CREATED = gql`
  subscription OnProspectBrochureCreated($brochureUUID: UUID!) {
    prospectBrochureCreated(brochureUUID: $brochureUUID) {
      __typename
      brochureUUID
      ... on ProspectBrochureData {
        brochureUrl
      }
      ... on ProspectBrochureError {
        error
      }
    }
  }
`

export {
  ADD_HOME_SELECTION,
  AGENTS_BY_CLIENT_QUERY,
  ADD_AGENT_PROSPECT,
  UPSERT_PROSPECT,
  BUILDER_APP_CONFIG,
  GET_CLIENT,
  GET_COMMUNITY_BY_NAME,
  GET_ELEVATION_COLORS,
  GET_FLOORPLANS_BY_ELEVATION_ID,
  GET_LOT,
  GET_LOT_SITEPLAN_INFO,
  GET_MASTER_COMMUNITY_BY_NAME,
  GET_SITEPLAN_LITE_BY_COMMUNITY_ID,
  GET_SITEPLAN_BY_COMMUNITY_ID,
  GET_SITEPLAN_LITE_BY_ID,
  GET_SITEPLAN_BY_ID,
  GET_SITEPLAN_LITE_BROCHURE_BY_COMMUNITY_ID,
  GET_LOT_SITEPLAN_BY_ID,
  GET_SALES_APP_CONFIG,
  GET_MASTER_SALES_APP_CONFIG,
  HOME_SELECTION,
  INVENTORY_CONSTRUCTION_STATUSES,
  COMMUNITY_WITH_PM_FLOORPLANS,
  GET_STD_FEATURE_ASSIGNMENTS,
  REPLACE_PROSPECT_FAVORITES,
  GET_PROSPECT_WITH_FAVORITES_BY_EMAIL,
  PLAN,
  LOT,
  COMMUNITY_LITE,
  DELETE_PROSPECT_FAVORITE_BY_EMAIL,
  UPDATE_PROSPECT_FAVORITES,
  ADD_PROSPECT_FAVORITES,
  PROSPECT_BROCHURE_CREATED,
}
