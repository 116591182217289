import {
  CLEAR_MATERIAL_COLORS,
  SET_APP_CONFIG,
  SET_MASTER_APP_CONFIG,
  SET_CLIENT,
  ADD_FP_OPT,
  REM_FP_OPT,
  RESET_SELECTED_FPOPTS,
  RESET_SELECTIONS,
  SET_SELECTED_HOME,
  RESET_SELECTED_LOT,
  SET_SITEPLAN,
  SET_DIRECTORY_NAME,
  SET_SELECTED_COMMUNITY,
  SET_SELECTED_MASTER_COMMUNITY,
  SET_SELECTED_SCHEME,
  RESET_SELECTED_SCHEME,
  SET_COMMUNITY_LOGO,
  SET_COMMUNITY_LOGO_VIEWBOX,
  SET_MASTER_COMMUNITY_LOGO,
  SET_MASTER_COMMUNITY_LOGO_VIEWBOX,
  SET_COMMUNITY_LOGO_WHITE,
  SET_COMMUNITY_LOGO_WHITE_VIEWBOX,
  SET_MATERIAL_COLOR,
  REMOVE_MATERIAL_COLOR,
  DELETE_FAVORITE_BY_INDEX,
  DELETE_ALL_FAVORITES,
  DUPLICATE_FAVORITES,
  RESTORE_FAVORITE,
  LIKE_SELECTION,
  SET_FP_OPTS,
  SET_FNUM,
  SET_AGENT,
  TOGGLE_MIRROR,
  TOGGLE_PROSPECT_FAVORITES_FETCHED,
  SET_BROCHURE_SELECTIONS,
  SET_ALL_FAVORITES_UPDATED,
  ADD_ALL_FAVORITES,
  UNLIKE_FAVORITE,
  UPDATE_FAVORITE_AFTER_DB_UPDATE,
} from '../constants/actionTypes'

import { getPath, not, pick, propEquals } from '@anewgo/functions'
import { siteplans } from '@anewgo/utils'
import { isInventoryLot } from '@anewgo/utils/dist/siteplans'
import { uniqueIndexForFavorite } from '../utils/favorites'
import isEqual from 'lodash.isequal'

import { sortFavorites } from '../utils/sorting'

const { availableSchemesForLot } = siteplans

const initialState = {
  id: null,
  agent: null,

  client: null, // client object

  appConfig: {}, // app config

  community: null,
  plan: null,
  elevation: null,
  // palette selection, or hybrid override
  materialColors: {},
  scheme: null,

  // selected floorplan options for each floor
  // each item will look like { fnum, opt }
  fpOpts: [],

  fnum: 1,
  mirror: false,

  // selected lot
  lot: null,

  // siteplan
  siteplan: null,

  // directoryName for assets
  directoryName: null,

  // community logo, SVG version
  communityLogo: null,

  // the plain or white version of the SVG
  // we need this because some logos have multiple colors
  communityLogoWhite: null,

  // the viewBox for the SVG
  communityLogoViewBox: null,
  communityLogoWhiteViewBox: null,

  // master-planned community
  masterCommunity: null,

  masterCommunityLogo: null,

  masterCommunityLogoViewBox: null,

  masterAppConfig: {},

  favorites: [],

  garagePosition: null,

  isProspectFavoritesFetched: false,

  duplicateNum: 0,
}

const formatFpOptsForUIReverse = (fpOptSelections) => {
  const fpOpts = []
  Object.keys(fpOptSelections).forEach((story) => {
    const obj = { opts: fpOptSelections[story], fnum: story }
    fpOpts.push(obj)
  })
  return fpOpts
}

const addAllFavorites = (favorites) => {
  const newFavorites = []

  favorites.forEach((favorite) => {
    let newFavorite = compileFavorite(favorite)
    newFavorite.updateToDb = false
    newFavorites.push(newFavorite)
  })

  return newFavorites
}

const formatFpOpts = (favorite) => {
  const formatOld =
    favorite.fpOptSelections &&
    Object.keys(favorite.fpOptSelections) &&
    Object.keys(favorite.fpOptSelections).length > 0
  const fpOpts = formatOld
    ? formatFpOptsForUIReverse(favorite.fpOptSelections)
    : favorite.fpOpts
  return fpOpts || []
}

const areFavoritesEqual = (newFav, old) => {
  const newer = {
    index: uniqueIndexForFavorite(newFav),
    communityId: getPath('community', 'id')(newFav),
    planId: getPath('plan', 'id')(newFav),
    elevationId: getPath('elevation', 'id')(newFav),
    lotId: getPath('lot', 'id')(newFav),
    schemeId: getPath('schemeId')(newFav) || getPath('scheme', 'id')(newFav),
    clientName: getPath('clientName')(newFav),
    id: newFav.id,
    colorSelections: getPath('colorSelections')(newFav),
    fpOptSelections: formatFpOpts(newFav),
    materialColors: newFav.colorSelections || newFav.materialColors,
  }
  const older = {
    index: uniqueIndexForFavorite(old),
    communityId: getPath('community', 'id')(old),
    planId: getPath('plan', 'id')(old),
    elevationId: getPath('elevation', 'id')(old),
    lotId: getPath('lot', 'id')(old),
    schemeId: getPath('schemeId')(old) || getPath('scheme', 'id')(old),
    clientName: getPath('clientName')(old),
    id: old.id,
    colorSelections: getPath('colorSelections')(old),
    fpOptSelections: formatFpOpts(old),
    materialColors: old.colorSelections || old.materialColors,
  }
  return isEqual(newer, older)
}

const compileFavorite = (fav, preventInsert) => {
  return {
    index: uniqueIndexForFavorite(fav),
    communityId: getPath('community', 'id')(fav),
    planId: getPath('plan', 'id')(fav),
    elevationId: getPath('elevation', 'id')(fav),
    lotId: getPath('lot', 'id')(fav),
    schemeId: getPath('schemeId')(fav) || getPath('scheme', 'id')(fav),
    clientName: getPath('clientName')(fav),
    community: fav.community,
    plan: fav.plan,
    elevation: fav.elevation,
    lot: fav.lot,
    scheme: fav.scheme,
    materialColors: fav.colorSelections || fav.materialColors,
    garagePosition: fav.garagePosition || null,
    fpOpts: formatFpOpts(fav),
    id: fav.id || null,
    duplicateNum: fav.duplicateNum || 0,
    colorSelections: {},
    liked: true,
    addToDb: preventInsert ? false : true,
    updateToDb: true,
  }
}
const upsertAnonymousFavorites = (stateFavorites, newFavorites, preventInsert) => {
  const compiledStateFavorites = stateFavorites.map((favorite) =>
    compileFavorite(favorite, preventInsert)
  ) // add unique identifier and other data

  return newFavorites.reduce(
    (favs, fav) => {
      const compiledNewFavorite = compileFavorite(fav, preventInsert)
      favs.forEach((oldFavorite, index) => {
        if (
          oldFavorite.index === compiledNewFavorite.index &&
          oldFavorite.duplicateNumber === compiledNewFavorite.duplicateNumber
        ) {
          favs.splice(index, 1)
          if (preventInsert) {
            // If we're preventing a new favorite insert, only push if we're replacing the old one.
            favs.push(compiledNewFavorite)
          }
        }
      })
      if (!preventInsert) {
        // If we're not preventing inserts, always add new favorites.
        favs.push(compiledNewFavorite)
      }
      return favs
    },
    [...compiledStateFavorites] // State favorites are always returned.
  )
}

// Merges all state favorites and prospect favorites and returns the merged array of all favorites
const upsertFavorites = (stateFavorites, favorite, preventInsert = false) => {
  let newFavorite
  if (!stateFavorites || stateFavorites.length === 0) {
    newFavorite = compileFavorite(favorite, preventInsert)
    return preventInsert ? [] : [newFavorite]
  }
  // This checks if new favorites have an ID, essentially confirming if the new favorites are DB
  // favorites that need to be updated.
  if (favorite && favorite.id) {
    //update favorite or mirror thingy
    const currentFavIndex = stateFavorites.findIndex((fav) => fav.id === favorite.id)

    // This check checks if the new favorite we have has a change in index
    //  This would essentially point to it being a mirrored favorite
    if (uniqueIndexForFavorite(favorite) !== favorite.index) {
      const alreadyExistingFavorite = stateFavorites.findIndex(
        (fav) => fav.index === uniqueIndexForFavorite(favorite)
      )
      // If the mirror of this favorite already exists
      if (alreadyExistingFavorite >= 0) {
        newFavorite = compileFavorite(favorite, preventInsert)
        newFavorite.id = stateFavorites[alreadyExistingFavorite].id
        // update if the new mirrored favorite is any different from the existing one
        if (!areFavoritesEqual(newFavorite, stateFavorites[alreadyExistingFavorite])) {
          stateFavorites.push(newFavorite)
          stateFavorites.splice(alreadyExistingFavorite, 1)
        }
      }
      // else if the mirror of the favorite is not yet in the DB add it in the store with an undefined id (it has an ID right now, we need to remove it)
      else if (!preventInsert) {
        newFavorite = compileFavorite(favorite, preventInsert)
        newFavorite.id = undefined
        stateFavorites.push(newFavorite)
      }
    }
    // update an existing favorite
    else {
      newFavorite = compileFavorite(favorite, preventInsert)
      stateFavorites.splice(currentFavIndex, 1)
      stateFavorites.push(newFavorite)
    }
  } else if (!preventInsert) {
    // anonymous favorites or added a new favorite

    // this condition checks if the favorites coming from state are DB favorites
    if (stateFavorites.length && stateFavorites[0].id) {
      const newFavorite = compileFavorite(favorite, preventInsert)

      const alreadyExistingIndex = stateFavorites.findIndex(
        (fav) => fav.index === newFavorite.index
      )
      if (alreadyExistingIndex === -1 && !preventInsert) {
        stateFavorites.push(newFavorite)
      }
    }
    // if we are on this point it means the user is not signed in and we deal with the favorites without in traditional non id way
    else {
      return upsertAnonymousFavorites(stateFavorites, [favorite], preventInsert)
    }
  }
  return sortFavorites(stateFavorites)
}

const updateSelectionAndFavorite = (state) => {
  return {
    ...state,
    favorites: upsertFavorites(
      state.favorites,
      {
        ...state,
        liked: true,
      },
      true // Send "true" for preventInsert
    ),
  }
}

const selectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_CONFIG: {
      const { config } = action
      return {
        ...state,
        appConfig: config,
      }
    }
    case SET_MASTER_APP_CONFIG: {
      const { config } = action
      return {
        ...state,
        masterAppConfig: config,
      }
    }

    case SET_CLIENT: {
      const { client } = action
      return {
        ...state,
        client,
      }
    }

    case SET_AGENT: {
      const { agent } = action
      return {
        ...state,
        agent,
      }
    }

    case RESET_SELECTIONS: {
      const { includingCommunity } = action
      const plan = null,
        elevation = null,
        scheme = null,
        lot = null,
        fpOpts = [],
        fnum = 1
      const materialColors = {}
      const community = includingCommunity ? null : state.community
      const siteplan = includingCommunity ? null : state.siteplan
      const communityLogo = includingCommunity ? null : state.communityLogo
      const communityLogoViewBox = includingCommunity ? null : state.communityLogoViewBox
      const communityLogoWhite = includingCommunity ? null : state.communityLogoWhite
      const communityLogoWhiteViewBox = includingCommunity
        ? null
        : state.communityLogoWhiteViewBox
      return {
        ...state,
        plan,
        elevation,
        lot,
        fpOpts,
        fnum,
        community,
        siteplan,
        materialColors,
        scheme,
        communityLogo,
        communityLogoViewBox,
        communityLogoWhite,
        communityLogoWhiteViewBox,
      }
    }
    case SET_BROCHURE_SELECTIONS: {
      const { dbSelections } = action
      const { lot, scheme, colorSelections, fpOptSelections } = dbSelections
      return {
        ...state,
        lot,
        scheme,
        materialColors: colorSelections,
        fpOpts: fpOptSelections,
      }
    }
    case ADD_FP_OPT: {
      const { fnum, fpOpt } = action
      let fpOpts = [...state.fpOpts]
      const story = fpOpts.find((story) => story.fnum === fnum)
      let tgtOpts = story ? story.opts : []
      let tgtIdx = fpOpts.findIndex((story) => story.fnum === fnum)
      tgtOpts = tgtOpts.map((opt) => Object.assign({}, opt))
      // see if exist
      const existIdx = tgtOpts.findIndex((opt) => opt.id === fpOpt.id)
      if (existIdx !== -1) {
        tgtOpts[existIdx] = fpOpt
      } else {
        tgtOpts.push(fpOpt)
      }
      if (tgtIdx !== -1) {
        fpOpts[tgtIdx] = { fnum, opts: tgtOpts }
      } else {
        fpOpts = [...fpOpts, { fnum, opts: tgtOpts }]
      }
      return updateSelectionAndFavorite({
        ...state,
        fpOpts,
      })
    }
    case REM_FP_OPT: {
      const { fnum, id } = action
      const fpOpts = [...state.fpOpts]
      const story = fpOpts.find((story) => story.fnum === fnum)
      let tgtOpts = story ? story.opts : []
      if (tgtOpts.length === 0) {
        return state
      }
      let tgtIdx = fpOpts.findIndex((story) => story.fnum === fnum)
      tgtOpts = tgtOpts.map((opt) => Object.assign({}, opt))
      tgtOpts = tgtOpts.filter((opt) => opt.id !== id)
      fpOpts[tgtIdx] = { fnum, opts: tgtOpts }
      return updateSelectionAndFavorite({
        ...state,
        fpOpts,
      })
    }
    case SET_FP_OPTS: {
      const { fpOpts } = action
      return updateSelectionAndFavorite({
        ...state,
        fpOpts,
      })
    }
    case RESET_SELECTED_FPOPTS: {
      const fpOpts = []
      return updateSelectionAndFavorite({
        ...state,
        fpOpts,
        fnum: 1,
      })
    }

    case SET_FNUM: {
      const { fnum } = action
      return {
        ...state,
        fnum,
      }
    }

    case TOGGLE_MIRROR: {
      const { mirror } = action
      return {
        ...state,
        mirror,
      }
    }

    case SET_SELECTED_MASTER_COMMUNITY: {
      const { masterCommunity } = action
      return {
        ...state,
        masterCommunity,
      }
    }
    case SET_MASTER_COMMUNITY_LOGO: {
      const { masterCommunityLogo } = action
      return {
        ...state,
        masterCommunityLogo,
      }
    }
    case SET_MASTER_COMMUNITY_LOGO_VIEWBOX: {
      const { masterCommunityLogoViewBox } = action
      return {
        ...state,
        masterCommunityLogoViewBox,
      }
    }
    case SET_COMMUNITY_LOGO: {
      const { communityLogo } = action
      return {
        ...state,
        communityLogo,
      }
    }
    case SET_COMMUNITY_LOGO_WHITE: {
      const { communityLogoWhite } = action
      return {
        ...state,
        communityLogoWhite,
      }
    }
    case SET_COMMUNITY_LOGO_VIEWBOX: {
      const { communityLogoViewBox } = action
      return {
        ...state,
        communityLogoViewBox,
      }
    }
    case SET_COMMUNITY_LOGO_WHITE_VIEWBOX: {
      const { communityLogoWhiteViewBox } = action
      return {
        ...state,
        communityLogoWhiteViewBox,
      }
    }
    case SET_SITEPLAN: {
      const { siteplan } = action
      return {
        ...state,
        siteplan,
      }
    }
    case SET_DIRECTORY_NAME: {
      const { directoryName } = action
      return {
        ...state,
        directoryName,
      }
    }
    case SET_SELECTED_COMMUNITY: {
      const { community } = action
      if (state.community && state.community.id !== community.id) {
        return {
          ...state,
          community,
          plan: initialState.plan,
          elevation: initialState.elevation,
          lot: initialState.lot,
          scheme: initialState.scheme,
          materialColors: initialState.materialColors,
          fpOpts: initialState.fpOpts,
        }
      } else {
        return {
          ...state,
          community,
        }
      }
    }
    case RESET_SELECTED_LOT: {
      const lot = null
      return updateSelectionAndFavorite({
        ...state,
        lot,
      })
    }
    case SET_SELECTED_HOME: {
      const { fpOpts, lot, mirror } = action.payload
      const plan = action.payload.plan || state.plan || initialState.plan
      const elevation =
        action.payload.elevation || state.elevation || initialState.elevation
      const garagePosition =
        elevation &&
        (elevation.garagePosition && mirror
          ? elevation.garagePosition === 'LEFT'
            ? 'RIGHT'
            : 'LEFT'
          : elevation.garagePosition)
      const updatePlanElevation =
        plan && elevation && (!lot || (lot && !isInventoryLot(lot)))

      // If an inventory lot is being selected without an elevation assigned to it
      // clear the currently selected plan and elevation
      const clearElevation =
        !action.payload.plan &&
        !action.payload.elevation &&
        lot.inventory &&
        lot.inventory.elevationId === null

      if (clearElevation) {
        return updateSelectionAndFavorite({
          ...state,
          plan: lot.inventory.plan,
          elevation: null,
          fpOpts: fpOpts || initialState.fpOpts,
          lot: lot || initialState.lot,
          mirror: mirror || initialState.mirror,
          garagePosition: garagePosition || initialState.garagePosition,
        })
      }

      if (!updatePlanElevation) {
        return updateSelectionAndFavorite({
          ...state,
          plan: lot.inventory.plan,
          elevation: lot.inventory.elevation || { id: lot.inventory.elevationId },
          fpOpts: fpOpts || initialState.fpOpts,
          lot: lot || initialState.lot,
          mirror: mirror || initialState.mirror,
          garagePosition: garagePosition || initialState.garagePosition,
        })
      }

      const planElevationChanged =
        getPath('id')(state.elevation) !== getPath('id')(elevation)

      if (planElevationChanged) {
        return updateSelectionAndFavorite({
          ...state,
          plan,
          elevation,
          fpOpts: fpOpts || initialState.fpOpts,
          lot: lot || initialState.lot,
          mirror: mirror || initialState.mirror,
          garagePosition: garagePosition || initialState.garagePosition,
        })
      }

      if (lot) {
        const availableSchemes = availableSchemesForLot(lot) || []
        let scheme = initialState.scheme

        if (availableSchemes.length === 1) {
          scheme = availableSchemes[0]
        } else if (
          state.scheme &&
          availableSchemes.some(propEquals('id', getPath('id')(state.scheme)))
        ) {
          scheme = state.scheme
        }

        return updateSelectionAndFavorite({
          ...state,
          lot,
          scheme,
          fpOpts: fpOpts || initialState.fpOpts,
          mirror: mirror || initialState.mirror,
          garagePosition: garagePosition || initialState.garagePosition,
        })
      }

      return updateSelectionAndFavorite({
        ...state,
        fpOpts: fpOpts || initialState.fpOpts,
        lot: lot || initialState.lot,
        mirror: mirror || initialState.mirror,
        garagePosition: garagePosition || initialState.garagePosition,
      })
    }
    case SET_SELECTED_SCHEME: {
      const { scheme } = action
      return updateSelectionAndFavorite({
        ...state,
        scheme,
      })
    }
    case RESET_SELECTED_SCHEME: {
      const scheme = null
      return updateSelectionAndFavorite({
        ...state,
        scheme,
      })
    }
    case SET_MATERIAL_COLOR: {
      const { material, color } = action
      return updateSelectionAndFavorite({
        ...state,
        materialColors: {
          ...state.materialColors,
          [material.materialId]: color,
        },
      })
    }
    case REMOVE_MATERIAL_COLOR: {
      const { materialId } = action
      const { materialColors } = state
      materialColors[materialId] = undefined
      return updateSelectionAndFavorite({
        ...state,
        materialColors,
      })
    }
    case CLEAR_MATERIAL_COLORS: {
      return updateSelectionAndFavorite({
        ...state,
        materialColors: {},
      })
    }
    case DELETE_FAVORITE_BY_INDEX: {
      return action.payload.id
        ? {
            ...state,
            favorites: state.favorites.filter(not(propEquals('id', action.payload.id))),
          }
        : {
            ...state,
            favorites: state.favorites.filter(
              (fav) => !(fav.index === action.payload.index)
            ),
          }
    }
    case DUPLICATE_FAVORITES: {
      const duplicateFavorites = action.payload.map((fav) => {
        const newDuplicate =
          Math.max(
            ...state.favorites
              .filter((item) => item.index === fav.index)
              .map((a) => a.duplicateNum || 0)
          ) + 1
        const index = uniqueIndexForFavorite({ ...fav, duplicateNum: newDuplicate })
        return {
          ...fav,
          duplicateNum: newDuplicate,
          index,
        }
      })
      return {
        ...state,
        favorites: [...state.favorites, ...duplicateFavorites],
      }
    }
    case DELETE_ALL_FAVORITES: {
      return {
        ...state,
        favorites: [],
      }
    }
    case RESTORE_FAVORITE: {
      return {
        ...state,
        id: action.payload.id,
        plan: action.payload.plan,
        elevation: action.payload.elevation,
        lot: action.payload.lot,
        scheme: action.payload.scheme,
        materialColors: action.payload.materialColors,
        fpOpts: action.payload.fpOpts,
        fnum: getPath('elevation', 'defaultFloor')(action.payload) || 1,
        mirror: false,
        duplicateNum: action.payload.duplicateNum || 0,
        garagePosition: getPath('garagePosition')(action.payload),
        index: getPath('index')(action.payload),
      }
    }
    case LIKE_SELECTION: {
      const favorite = {
        ...action.payload,
        liked: true,
      }

      const newFavorite = uniqueIndexForFavorite(favorite)

      const duplicateFavs = state.favorites.filter((fav) => {
        const oldFavorite = uniqueIndexForFavorite(fav)
        return oldFavorite === newFavorite
      })
      if (duplicateFavs && duplicateFavs.length > 0) {
        favorite.duplicateNum = duplicateFavs[0].duplicateNum
      }
      return {
        ...state,
        favorites: upsertFavorites(state.favorites, favorite),
      }
    }
    case TOGGLE_PROSPECT_FAVORITES_FETCHED: {
      return {
        ...state,
        isProspectFavoritesFetched: !state.isProspectFavoritesFetched,
      }
    }
    case UPDATE_FAVORITE_AFTER_DB_UPDATE: {
      let favWithNoId = state.favorites.find((fav) => !fav.id)
      let rest = state.favorites.filter((fav) => !!fav.id)
      if (!favWithNoId) {
        rest = state.favorites.filter((fav) => !(fav.id === action.payload.id))
      }
      return {
        ...state,
        id: action.payload.id,
        favorites: [...rest, action.payload],
      }
    }
    case SET_ALL_FAVORITES_UPDATED: {
      return {
        ...state,
        favorites: state.favorites.map((fav) => ({
          ...fav,
          updateToDb: false,
        })),
      }
    }
    case ADD_ALL_FAVORITES: {
      return {
        ...state,
        favorites: addAllFavorites(action.payload),
      }
    }
    case UNLIKE_FAVORITE: {
      const favorites = [...state.favorites]
      const favorite = action.payload
      favorites.forEach((fav) => {
        if (fav.like) {
          if (
            fav.community.id === favorite.community.id &&
            fav.plan.id === favorite.plan.id &&
            fav.elevation.id === favorite.elevation.id
          ) {
            fav.like = false
          }
        }
      })
      return {
        ...state,
        favorites,
      }
    }
    default:
      return state
  }
}

export const getAgent = (state) => state.agent
export const getAppConfig = (state) => state.appConfig
export const getMasterAppConfig = (state) => state.masterAppConfig
export const getSelection = pick(
  'community',
  'plan',
  'elevation',
  'lot',
  'scheme',
  'materialColors',
  'fpOpts'
)
export const getSelectedClient = (state) => state.client
export const getSelectedMasterCommunity = (state) => state.masterCommunity
export const getSelectedCommunity = (state) => state.community
export const getSelectedPlan = (state) => state.plan
export const getSelectedElevation = (state) => state.elevation
export const getSelectedScheme = (state) => state.scheme
export const getMaterialColors = (state) => state.materialColors
export const getSelectedFpOpts = (state) => state.fpOpts
export const getFnum = (state) => state.fnum
export const getMirror = (state) => state.mirror
export const getSelectedLot = (state) => state.lot
export const getSiteplan = (state) => state.siteplan
export const getDirectoryName = (state) => state.directoryName
export const getCommunityLogo = (state) => state.communityLogo
export const getCommunityLogoWhite = (state) => state.communityLogoWhite
export const getCommunityLogoViewBox = (state) => state.communityLogoViewBox
export const getCommunityLogoWhiteViewBox = (state) => state.communityLogoWhiteViewBox
export const getMasterCommunityLogo = (state) => state.masterCommunityLogo
export const getMasterCommunityLogoViewBox = (state) => state.masterCommunityLogoViewBox
export const getFavorites = (state) => state.favorites
export const getIsProspectFavoritesFetched = (state) => state.isProspectFavoritesFetched

export default selectionsReducer
