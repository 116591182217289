import {
  SET_AGENT,
  SET_PROSPECT,
  SET_PROSPECT_RESERVATIONS,
  SET_ANONYMOUS_PROSPECT,
  SET_INFO_MODAL,
  RESET_INFO_MODAL,
  SET_COMMUNITIES_LIST_INDEX,
  SET_COMMUNITIES_MAP_BOUNDS,
  SET_PLANS_FILTERS_BATH,
  SET_PLANS_FILTERS_BED,
  SET_FLOORPLANS_VIEW,
  SET_SITEPLAN_NAME,
  TOGGLE_FAV_MENU,
  TOGGLE_COMPARE_FAVORITES,
  SET_SITEPLAN_ID,
} from '../constants/actionTypes'

import { VIEW_PLANS_GALLERY } from '../constants'

const initialState = {
  openInfoModal: false,
  infoModalMsg: '',
  infoModalTitle: '',
  progressModalMsg: '',
  communitiesMapBounds: {
    southBound: 0.0,
    offsetLng: 0.0,
    offsetLat: 0.0,
    eastBound: 0.0,
  },
  plansFilters: {
    bed: 1,
    bath: 1.0,
  },
  prospect: null,
  anonymousProspect: null,
  agent: null,
  floorplansView: VIEW_PLANS_GALLERY,
  siteplanName: null,
  favMenuOpen: false,
  compareFavoritesOpen: false,
}

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO_MODAL: {
      const { openInfoModal, infoModalMsg, infoModalTitle, progressModalMsg } = action
      return {
        ...state,
        infoModalMsg,
        infoModalTitle: infoModalTitle || '',
        progressModalMsg,
        openInfoModal,
      }
    }
    case RESET_INFO_MODAL: {
      const infoModalMsg = ''
      const progressModalMsg = ''
      const openInfoModal = false
      return {
        ...state,
        infoModalMsg,
        progressModalMsg,
        openInfoModal,
      }
    }
    case SET_AGENT: {
      const { agent } = action
      return {
        ...state,
        agent,
      }
    }
    case SET_PROSPECT: {
      const { prospect } = action
      return {
        ...state,
        prospect,
      }
    }
    case SET_PROSPECT_RESERVATIONS: {
      const { reservations } = action
      return {
        ...state,
        prospectReservations: reservations,
      }
    }
    case SET_ANONYMOUS_PROSPECT: {
      const { anonymousProspect } = action
      return {
        ...state,
        anonymousProspect,
      }
    }
    case SET_COMMUNITIES_MAP_BOUNDS: {
      const { bounds } = action
      return {
        ...state,
        communitiesMapBounds: bounds,
      }
    }
    case SET_COMMUNITIES_LIST_INDEX: {
      return {
        ...state,
        communitiesListIndex: action.index,
      }
    }
    case SET_FLOORPLANS_VIEW: {
      const { floorplansView } = action
      return {
        ...state,
        floorplansView,
      }
    }
    case SET_PLANS_FILTERS_BED: {
      const { plansFilters } = state
      plansFilters.bed = action.bed
      return {
        ...state,
        plansFilters,
      }
    }
    case SET_PLANS_FILTERS_BATH: {
      const { plansFilters } = state
      plansFilters.bath = action.bath
      return {
        ...state,
        plansFilters,
      }
    }
    case SET_SITEPLAN_NAME: {
      return {
        ...state,
        siteplanName: action.siteplanName,
      }
    }
    case SET_SITEPLAN_ID: {
      return {
        ...state,
        siteplanId: action.siteplanId,
      }
    }
    case TOGGLE_FAV_MENU: {
      return {
        ...state,
        favMenuOpen: !state.favMenuOpen,
      }
    }
    case TOGGLE_COMPARE_FAVORITES: {
      return {
        ...state,
        compareFavoritesOpen: !state.compareFavoritesOpen,
      }
    }
    default:
      return state
  }
}

export const getCommunitiesMapBounds = (state) => state.communitiesMapBounds

export const getPlansFiltersBed = (state) => state.plansFilters.bed

export const getPlansFiltersBath = (state) => state.plansFilters.bath

export const getProspect = (state) => state.prospect

export const getAnonymousProspect = (state) => state.anonymousProspect

export const getAgent = (state) => state.agent

export const getProspectReservations = (state) => state.prospectReservations

export const getInfoModalMsg = (state) => state.infoModalMsg

export const getInfoModalTitle = (state) => state.infoModalTitle

export const getProgressModalMsg = (state) => state.progressModalMsg

export const getOpenInfoModal = (state) => state.openInfoModal

export const getFloorplansView = (state) => state.floorplansView

export const getSiteplanName = (state) => state.siteplanName

export const getSiteplanId = (state) => state.siteplanId

export const getFavMenuOpen = (state) => state.favMenuOpen

export const getCompareFavoritesOpen = (state) => state.compareFavoritesOpen

export default uiReducer
