import 'whatwg-fetch'
import Fingerprint2 from 'fingerprintjs2'
import { getPath } from '@anewgo/functions'
import chroma from 'chroma-js'
import { API_KEY } from '../constants'
import { getCloudPrefix, cloudinaryVideoPrefix } from '@anewgo/utils'
const __log = process.env.NODE_ENV === 'development'

export const WciLogoIcon = (props) => {
  const { width, height, fill } = props
  const url = 'https://res.cloudinary.com/renderinghouse/image/upload/app/wci/images/image-20230110-172501.png'
  return (
    <img src={url} alt="" style={{ maxWidth: '100%' }} />
  )
}

export const getHomeIdentifier = (selection) => {
  const values = []
  values.push(getPath('community', 'name')(selection))
  values.push(getPath('community', 'id')(selection))
  values.push(getPath('plan', 'name')(selection))
  values.push(getPath('plan', 'id')(selection))
  values.push(getPath('elevation', 'caption')(selection))
  values.push(getPath('elevation', 'id')(selection))
  values.push(getPath('lot', 'id')(selection))
  if (getPath('lot', 'inventory')(selection)) {
    values.push(getPath('lot', 'inventory', 'id')(selection))
  }
  const homeId = Fingerprint2.x64hash128(values.join(''), 31)
  return homeId
}

export const determineGaragePositionCaption = (elevation, mirror) => {
  let garagePos
  if (elevation && elevation.garagePosition && !elevation.mirrorElevationId) {
    if (elevation.garagePosition === 'LEFT') {
      if (mirror) {
        garagePos = 'Right'
      } else {
        garagePos = 'Left'
      }
    } else if (elevation.garagePosition === 'RIGHT') {
      if (mirror) {
        garagePos = 'Left'
      } else {
        garagePos = 'Right'
      }
    }
  }
  return garagePos
}

/**
 * This function is used to return a copy of elevationColors updated with
 * content from mirrorElevationColors. Use this function when your current
 * elevation doesn't have content (layers, schemes, materialPalettes, base)
 * and can re-use the content from the mirror elevation.
 * @param {*} elevationColors
 * @param {*} mirrorElevationColors
 */
export const applyMirrorContent = (elevationColors, mirrorElevationColors) => {
  // we could potentially have a stricter check
  if (!mirrorElevationColors || elevationColors.layers.length > 0) {
    return [elevationColors, false]
  }
  const elevationColorsCopy = { ...elevationColors }
  // borrow content from mirror elevation
  elevationColorsCopy.id = mirrorElevationColors.id
  elevationColorsCopy.thumb = mirrorElevationColors.thumb
  elevationColorsCopy.base = mirrorElevationColors.base
  elevationColorsCopy.layers = mirrorElevationColors.layers
  elevationColorsCopy.materialPalettes = mirrorElevationColors.materialPalettes
  if (elevationColors.schemes.length === 0) {
    elevationColorsCopy.schemes = mirrorElevationColors.schemes
  }
  return [elevationColorsCopy, true] // set to true, i.e. using mirror
}

export const mirrorElevationImage = (elevation, mirror) =>
  (!mirror &&
    elevation &&
    elevation.garagePosition &&
    elevation.defaultGaragePosition &&
    elevation.garagePosition !== elevation.defaultGaragePosition) ||
  (mirror &&
    elevation &&
    elevation.garagePosition &&
    elevation.defaultGaragePosition &&
    elevation.garagePosition === elevation.defaultGaragePosition)

export const debugLog = (msg, ...args) => {
  if (!__log) return
  console.log(msg, ...args)
}

export const userIdentifier = (prospect, anonymousProspect) =>
  prospect ? `prospect-${prospect.id}` : anonymousProspect

export const graphqlProps =
  (
    dataKey,
    {
      optional = false,
      mapResultsToProps = (results) => ({ [dataKey]: results }),
      debugIdentifier = '',
      mapResultsToDebugValue,
    } = {}
  ) =>
  (results) => {
    const debugIdentifierText = `utils.graphqlProps(${debugIdentifier})`
    const { data, ownProps } = results

    if (data.loading) return { loading: true }
    if (data.error) {
      console.error(`${debugIdentifierText}: Error loading GraphQL:`, data.error)
      return { error: data.error }
    }

    const dataKeyResults = data[dataKey]
    if (!optional && !dataKeyResults) {
      console.error(
        `${debugIdentifierText}: Error loading GraphQL (empty data):`,
        results
      )
      return { error: true }
    }

    return mapResultsToProps(dataKeyResults, ownProps)
  }

/**
 * Helper to convert database constants, such as from enums, to displayable text.
 * Example: `prettifyString('UNDER_CONSTRUCTION')` equals "Under Construction".
 */
export const prettifyString = (context) => {
  const casedString = context.charAt(0).toUpperCase() + context.toLowerCase().slice(1)
  return casedString.replace(/_([a-zA-Z])/g, (match) => ` ${match[1].toUpperCase()}`)
}

const millisecondsInOneDay = 86400000

export const futureTimestamp = ({ daysOut = 0 } = {}) => {
  return Date.now() + daysOut * millisecondsInOneDay
}

export const daysUntil = (timestamp) => {
  return Math.ceil((timestamp - Date.now()) / millisecondsInOneDay)
}

export const transpose = (array, ...keys) => {
  const data = []
  for (var i = 0; i < keys.length; i++) {
    data.push([])
  }

  array.forEach((object) => {
    keys.forEach((key, index) => {
      data[index].push(object[key])
    })
  })

  return data
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// returns a clean string that only allows [a-z][A-Z][0-9]
export const cleanString = (input) => {
  return input.replace(/[^a-zA-Z0-9]+/gi, '_')
}

// ugh...react-router#4683
export const withoutStaticContextProp = ({ staticContext, ...rest }) => ({ ...rest })

// returns either white or black for a given color
// this function can be used to return a suitable text color to use given an
// input of a background color
export const getTextColor = (hex) => {
  const lum = chroma(hex).luminance()
  return lum > 0.7 ? '000000' : 'ffffff'
}

export const hexToRGB = (hex) => {
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return [r, g, b]
}

export const getColorThemeEntryByRank = (entries, rank) => {
  const entry = entries.find((tgt) => tgt.rank === rank)
  if (entry) {
    return `#${entry.hex}`
  }
  return '#FFFFFF'
}

export const getThumbnailPrefix = (directoryName, transformBit) => {
  const prefix = `${getCloudPrefix(transformBit)}/${directoryName}/thumbnails`
  return prefix
}

export const getVideoAssetsPrefix = (directoryName) => {
  const prefix = `${cloudinaryVideoPrefix}/app/${directoryName}/assets`
  return prefix
}

export const getCustomPrefix = (directoryName, transformBit) => {
  const prefix = `${getCloudPrefix(transformBit)}/${directoryName}/assets/custom`
  return prefix
}

export const formatCost = (value) =>
  value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('.')[0]

export const loadCommunityLogo = (url, setCommunityLogo, setCommunityLogoViewBox) => {
  fetch(url)
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('Bad response from server', response.status)
      }
      return response.text()
    })
    .then((ret) => {
      const regExp = /viewBox="/g
      const viewBoxIdx = ret.search(regExp)
      if (viewBoxIdx !== -1) {
        const endIdx = ret.indexOf('"', viewBoxIdx + 9)
        const viewBoxValues = ret.substring(viewBoxIdx + 9, endIdx)
        setCommunityLogo(ret)
        setCommunityLogoViewBox(viewBoxValues)
      }
    })
    .catch((error) => {
      /* eslint-disable no-console */
      console.error('error fetching:', error)
    })
}

export const constructPath = (pathname, dst, spliceOffCount = 1) => {
  let routeChunks = pathname.split('/')
  if (routeChunks[routeChunks.length - 1] === '') {
    routeChunks.splice(routeChunks.length - 1, 1)
  }
  routeChunks.splice(0, 1) // there will be an empty string in the front
  for (let i = 0; i < spliceOffCount; i++) {
    routeChunks.splice(routeChunks.length - 1, 1)
  }
  const reducer = (accum, word) => `${accum}/${word}`
  let newPath = routeChunks.reduce(reducer, '') + `/${dst}`
  return newPath
}

export const prefetchLayers = (engineUrl, elevationId) => {
  const url = `${engineUrl}/prefetch?key=${API_KEY}&elevId=${elevationId}`
  fetch(url)
    .then((response) => {
      /* eslint-disable no-console */
      console.log('prefetched layers:', response)
    })
    .catch((error) => {
      /* eslint-disable no-console */
      console.error('error in prefetching layers:', error)
    })
}

export const shouldInventoryFlip = (inventory, elevation) => {
  if (!inventory || !elevation) {
    return false
  }
  if (inventory?.garagePosition && elevation?.svgMirroring) {
    if (
      elevation.garagePosition &&
      inventory.garagePosition !== elevation.garagePosition
    ) {
      return true
    } else if (!elevation.garagePosition && inventory.garagePosition !== 'LEFT') {
      return true
    }
  }
  return false
}

export const getPrice = (
  isInventory,
  cost,
  baseCost,
  inventoryPrice,
  isPricingEnabled
) => {
  if (!isPricingEnabled) return 'Contact Us For Pricing'

  if (isInventory) {
    if (inventoryPrice === null) {
      return 'Coming Soon'
    } else if (inventoryPrice === 0) {
      return 'Contact Us For Pricing'
    } else {
      return formatCost(inventoryPrice)
    }
  } else if (cost) {
    return formatCost(cost)
  } else if (baseCost) {
    return `Starting at ${formatCost(baseCost)}`
  }

  return null
}

export const getSubSiteplanName = (selectedLot, selectedCommunity) => {
  const selectedSiteplanId = selectedLot?.siteplanInfo?.siteplanId
  const masterSiteplanId = selectedCommunity?.primarySiteplan?.id
  let subSiteplanName = '';

  if (selectedSiteplanId && masterSiteplanId && selectedSiteplanId !== masterSiteplanId) {
    subSiteplanName = selectedLot?.siteplanDisplayName || selectedLot?.siteplanName;
  }

  return subSiteplanName
}