import { combineReducers } from 'redux'
import uiReducer, * as uiStateSelector  from './uiReducer'
import selectionsReducer, * as selectionsStateSelector from './selectionsReducer'

export const rootReducer = combineReducers({
  uiReducer,
  selectionsReducer,
})

// selection reducers
export const getSelectedClient = state =>
  selectionsStateSelector.getSelectedClient(state.selectionsReducer)

export const getSelectedMasterCommunity = state =>
  selectionsStateSelector.getSelectedMasterCommunity(state.selectionsReducer)

export const getSelection = state =>
  selectionsStateSelector.getSelection(state.selectionsReducer)

export const getSelectedCommunity = state =>
  selectionsStateSelector.getSelectedCommunity(state.selectionsReducer)

export const getSelectedPlan = state =>
  selectionsStateSelector.getSelectedPlan(state.selectionsReducer)

export const getSelectedElevation = state =>
  selectionsStateSelector.getSelectedElevation(state.selectionsReducer)

export const getSelectedScheme = state =>
  selectionsStateSelector.getSelectedScheme(state.selectionsReducer)

export const getMaterialColors = state =>
  selectionsStateSelector.getMaterialColors(state.selectionsReducer)

export const getSelectedFpOpts = state =>
  selectionsStateSelector.getSelectedFpOpts(state.selectionsReducer)

export const getFnum = state => selectionsStateSelector.getFnum(state.selectionsReducer)

export const getSelectedLot = state =>
  selectionsStateSelector.getSelectedLot(state.selectionsReducer)

export const getSiteplan = state =>
  selectionsStateSelector.getSiteplan(state.selectionsReducer)

export const getDirectoryName = state =>
  selectionsStateSelector.getDirectoryName(state.selectionsReducer)

export const getCommunityLogo = state =>
  selectionsStateSelector.getCommunityLogo(state.selectionsReducer)

export const getCommunityLogoWhite = state =>
  selectionsStateSelector.getCommunityLogoWhite(state.selectionsReducer)

export const getCommunityLogoViewBox = state =>
  selectionsStateSelector.getCommunityLogoViewBox(state.selectionsReducer)

export const getCommunityLogoWhiteViewBox = state =>
  selectionsStateSelector.getCommunityLogoWhiteViewBox(state.selectionsReducer)

export const getMasterCommunityLogo = state =>
  selectionsStateSelector.getMasterCommunityLogo(state.selectionsReducer)

export const getMasterCommunityLogoViewBox = state =>
  selectionsStateSelector.getMasterCommunityLogoViewBox(state.selectionsReducer)

export const getMirror = state => selectionsStateSelector.getMirror(state.selectionsReducer)

export const getAppConfig = state =>
  selectionsStateSelector.getAppConfig(state.selectionsReducer)

export const getMasterAppConfig = state =>
  selectionsStateSelector.getMasterAppConfig(state.selectionsReducer)

export const getFavorites = state =>
  selectionsStateSelector.getFavorites(state.selectionsReducer)

export const getProspectReservations = state =>
  uiStateSelector.getProspectReservations(state.uiReducer)

export const getIsProspectFavoritesFetched = state =>
  selectionsStateSelector.getIsProspectFavoritesFetched(state.selectionsReducer)

// ui reducers
export const getOpenInfoModal = state =>
  uiStateSelector.getOpenInfoModal(state.uiReducer)

export const getInfoModalMsg = state =>
  uiStateSelector.getInfoModalMsg(state.uiReducer)

export const getInfoModalTitle = state =>
  uiStateSelector.getInfoModalTitle(state.uiReducer)

export const getProgressModalMsg = state =>
  uiStateSelector.getProgressModalMsg(state.uiReducer)

export const getAgent = state =>
  uiStateSelector.getAgent(state.uiReducer)

export const getProspect = state =>
  uiStateSelector.getProspect(state.uiReducer)

export const getAnonymousProspect = state =>
  uiStateSelector.getAnonymousProspect(state.uiReducer)

export const getCommunitiesMapBounds = state =>
  uiStateSelector.getCommunitiesMapBounds(state.uiReducer)

export const getPlansFiltersBed = state =>
  uiStateSelector.getPlansFiltersBed(state.uiReducer)

export const getPlansFiltersBath = state =>
  uiStateSelector.getPlansFiltersBath(state.uiReducer)

export const getFloorplansView = state =>
  uiStateSelector.getFloorplansView(state.uiReducer)

export const getSiteplanName = state =>
  uiStateSelector.getSiteplanName(state.uiReducer)

export const getSiteplanId = state =>  
  uiStateSelector.getSiteplanId(state.uiReducer)

export const getFavMenuOpen = state =>
  uiStateSelector.getFavMenuOpen(state.uiReducer)

export const getCompareFavoritesOpen = state =>
  uiStateSelector.getCompareFavoritesOpen(state.uiReducer)
