// ui actions
export const SET_ANONYMOUS_PROSPECT = 'SET_ANONYMOUS_PROSPECT'
export const SET_COMMUNITIES_MAP_BOUNDS = 'SET_COMMUNITIES_MAP_BOUNDS'
export const SET_COMMUNITIES_LIST_INDEX = 'SET_COMMUNITIES_LIST_INDEX'
export const SET_PLANS_FILTERS_BED = 'SET_PLANS_FILTERS_BED'
export const SET_PLANS_FILTERS_BATH = 'SET_PLANS_FILTERS_BATH'
export const SET_PROSPECT = 'SET_PROSPECT'
export const SET_PROSPECT_RESERVATIONS = 'SET_PROSPECT_RESERVATIONS'
export const SET_AGENT='SET_AGENT'
export const SET_INFO_MODAL='SET_INFO_MODAL'
export const RESET_INFO_MODAL='RESET_INFO_MODAL'
export const SET_SITEPLAN_ID = 'SET_SITEPLAN_ID'

// selections actions
export const SET_APP_CONFIG='SET_APP_CONFIG'
export const SET_MASTER_APP_CONFIG='SET_MASTER_APP_CONFIG'
export const SET_CLIENT='SET_CLIENT'
export const RESET_SELECTIONS='RESET_SELECTIONS'
export const SET_DIRECTORY_NAME = 'SET_DIRECTORY_NAME'
export const SET_SITEPLAN = 'SET_SITEPLAN'
export const SET_SELECTED_COMMUNITY = 'SET_SELECTED_COMMUNITY'
export const SET_SELECTED_SCHEME='SET_SELECTED_SCHEME'
export const SET_SITEPLAN_NAME = 'SET_SITEPLAN_NAME'
export const RESET_SELECTED_SCHEME='RESET_SELECTED_SCHEME'
export const SET_SELECTED_MASTER_COMMUNITY='SET_SELECTED_MASTER_COMMUNITY'
export const SET_SELECTED_HOME = 'SET_SELECTED_HOME'
export const RESET_SELECTED_LOT='RESET_SELECTED_LOT'
export const DELETE_FAVORITE_BY_INDEX = 'DELETE_FAVORITE_BY_INDEX'
export const DELETE_ALL_FAVORITES = 'DELETE_ALL_FAVORITES'
export const DUPLICATE_FAVORITES = 'DUPLICATE_FAVORITES'
export const RESTORE_FAVORITE = 'RESTORE_FAVORITE'
export const UPDATE_FAVORITE_AFTER_DB_UPDATE = 'UPDATE_FAVORITE_AFTER_DB_UPDATE'
export const LIKE_SELECTION = 'LIKE_SELECTION'

export const SET_MATERIAL_COLOR='SET_MATERIAL_COLOR'
export const REMOVE_MATERIAL_COLOR='REMOVE_MATERIAL_COLOR'
export const CLEAR_MATERIAL_COLORS='CLEAR_MATERIAL_COLORS'
export const SET_BROCHURE_SELECTIONS='SET_BROCHURE_SELECTIONS'
export const ADD_FP_OPT='ADD_FP_OPT'
export const REM_FP_OPT='REM_FP_OPT'
export const SET_FP_OPTS='SET_FP_OPTS'
export const RESET_SELECTED_FPOPTS='RESET_SELECTED_FPOPTS'
export const SET_FNUM = 'SET_FNUM'

export const SET_COMMUNITY_LOGO='SET_COMMUNITY_LOGO'
export const SET_COMMUNITY_LOGO_WHITE='SET_COMMUNITY_LOGO_WHITE'
export const SET_COMMUNITY_LOGO_VIEWBOX='SET_COMMUNITY_LOGO_VIEWBOX'
export const SET_COMMUNITY_LOGO_WHITE_VIEWBOX='SET_COMMUNITY_LOGO_WHITE_VIEWBOX'

export const SET_MASTER_COMMUNITY_LOGO='SET_MASTER_COMMUNITY_LOGO'
export const SET_MASTER_COMMUNITY_LOGO_VIEWBOX='SET_MASTER_COMMUNITY_LOGO_VIEWBOX'

export const SET_FLOORPLANS_VIEW='SET_FLOORPLANS_VIEW'

export const TOGGLE_MIRROR='TOGGLE_MIRROR'
export const TOGGLE_FAV_MENU = 'TOGGLE_FAV_MENU'
export const TOGGLE_COMPARE_FAVORITES = 'TOGGLE_COMPARE_FAVORITES'
export const TOGGLE_PROSPECT_FAVORITES_FETCHED = 'TOGGLE_PROSPECT_FAVORITES_FETCHED'
export const SET_ALL_FAVORITES_UPDATED = 'SET_ALL_FAVORITES_UPDATED'
export const SET_REPLACE_FAVORITES_CALLED = 'SET_REPLACE_FAVORITES_CALLED'
export const ADD_ALL_FAVORITES = 'ADD_ALL_FAVORITES'
export const UNLIKE_FAVORITE = 'UNLIKE_FAVORITE'