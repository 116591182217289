import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom'
import { NO_MATCH_BG, NO_MATCH_MSG } from '../constants'
import Loading from '../components/Loading'


const NoMatch = lazy(() => import('./NoMatch'))



const MasterCommunityWithData = lazy(() => import('../components/MasterCommunityWithData'))
const CommunityWithData = lazy(() => import('../components/CommunityWithData'))
const IntroContainer = lazy(() => import('../pages/IntroContainer'))
const Home = lazy(() => import('../pages/Home'))
const Community = lazy(() => import('../pages/Community'))
const MainContainer = lazy(() => import('../pages/MainContainer'))
const OverviewContainer = lazy(() => import('../pages/OverviewContainer'))
const AreaMapContainer = lazy(() => import('../pages/AreaMapContainer'))
const GalleryContainer = lazy(() => import('../pages/GalleryContainer'))
const HomesitesContainer = lazy(() => import('../pages/HomesitesContainer'))
const FloorplanContainer = lazy(() => import('../pages/FloorplanContainer'))
const FloorplansContainer = lazy(() => import('../pages/FloorplansContainer'))
const PlainBrochureContainer = lazy(() => import('../pages/PlainBrochureContainer'))
const MasterCommunityContainer = lazy(() => import('../pages/MasterCommunityContainer'))
const MasterSiteplanContainer = lazy(() => import('../pages/MasterSiteplanContainer'))
const PMFloorplansContainer = lazy(() => import('../pages/PMFloorplansContainer'))
const AdaPolicy = lazy(() => import('../pages/AdaPolicy/AdaPolicy'))
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy'))

const BASE_COMMUNITY_PATH = '/client/:clientName/community/:communityName'
const MASTER_COMMUNITY_PATH = '/client/:clientName/master/:masterCommunityName'
const SUB_COMMUNITY_PATH = '/client/:clientName/master/:masterCommunityName/community/:communityName'

const localStyles = {
  height: 'inherit',
}

export const createRoutes = () => (
  <div style={localStyles}>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route
          exact
          path={`${BASE_COMMUNITY_PATH}`}
          render={() => <CommunityWithData render={() => <Community />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/intro`}
          render={() => <CommunityWithData render={() => <IntroContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/main`}
          render={() => <CommunityWithData render={() => <MainContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/overview`}
          render={() => <CommunityWithData render={() => <OverviewContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/areamap`}
          render={() => <CommunityWithData render={() => <AreaMapContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/gallery`}
          render={() => <CommunityWithData render={() => <GalleryContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/homesites`}
          render={() => <CommunityWithData render={() => <HomesitesContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/floorplan/:planName`}
          render={() => <CommunityWithData render={() => <FloorplanContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/floorplans`}
          render={() => <CommunityWithData render={() => <FloorplansContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/pmfloorplans`}
          render={() => <CommunityWithData render={() => <PMFloorplansContainer />} />}
        />
        <Route
          path={`${SUB_COMMUNITY_PATH}/pmfloorplans`}
          render={() => <CommunityWithData render={() => <PMFloorplansContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/plan/:planName/print/brochure`}
          render={() => <CommunityWithData render={() => <PlainBrochureContainer />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/inventory/print/brochure`}
          render={() => <CommunityWithData render={() => <PlainBrochureContainer />} />}
        />
        <Route
          exact
          path={`${MASTER_COMMUNITY_PATH}`}
          render={() => <MasterCommunityWithData render={() => <MasterCommunityContainer />} />}
        />
        <Route
          path={`${MASTER_COMMUNITY_PATH}/siteplan`}
          render={() => <MasterCommunityWithData render={() => <MasterSiteplanContainer />} />}
        />
        <Route
          path={`${SUB_COMMUNITY_PATH}/main`}
          render={() => <CommunityWithData render={() => <MainContainer />} />}
        />
        <Route
          path={`${SUB_COMMUNITY_PATH}/areamap`}
          render={() => <CommunityWithData render={() => <AreaMapContainer />} />}
        />
        <Route
          path={`${SUB_COMMUNITY_PATH}/overview`}
          render={() => <CommunityWithData render={() => <OverviewContainer />} />}
        />
        <Route
          path={`${SUB_COMMUNITY_PATH}/gallery`}
          render={() => <CommunityWithData render={() => <GalleryContainer />} />}
        />
        <Route
          path={`${SUB_COMMUNITY_PATH}/floorplans`}
          render={() => <CommunityWithData render={() => <FloorplansContainer />} />}
        />
        <Route
          path={`${SUB_COMMUNITY_PATH}/homesites`}
          render={() => <CommunityWithData render={() => <HomesitesContainer />} />}
        />
        <Route
            path={`${BASE_COMMUNITY_PATH}/adapolicy`}
            render={() => <CommunityWithData render={() => <AdaPolicy />} />}
        />
        <Route
          path={`${BASE_COMMUNITY_PATH}/privacypolicy`}
          render={() => <CommunityWithData render={() => <PrivacyPolicy />} />}
        />
        <Route render={() => <NoMatch bgImg={NO_MATCH_BG} msg={NO_MATCH_MSG} />} />
      </Switch>
    </Suspense>
  </div>
)
