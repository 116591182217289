export const DEFAULT_PRIMARY_COLOR = '#003865'
export const DEFAULT_SECONDARY_COLOR ='#15AAE2'
export const ISP_MODE_VIEW = 'ISP_MODE_VIEW'
export const ISP_MODE_MOVE = 'ISP_MODE_MOVE'
export const ISP_MODE_REMOVE = 'ISP_MODE_REMOVE'
export const ISP_MODE_ADD = 'ISP_MODE_ADD'
export const MODAL_DRAWER_HEADER_HT = 60
export const HEADER_HT = 72
export const MOBILE_BREAKPOINT = 768

export const NO_SELECTION_ID = -1

export const API_KEY = '376'

export const MAX_PLANCARD_MEDIA_HT = 476

export const VIEW_DESIGN_EXTERIOR = 'exterior'
export const VIEW_DESIGN_FLOORPLAN = 'floorplan'
export const VIEW_SELECT_HOMESITE = 'homesite'
export const VIEW_PLANS_GALLERY = 'plans'
export const VIEW_DESCRIPTION = 'description'
export const VIEW_PHOTO_GALLERY = 'photos'
export const VIEW_CREATE_BROCHURE = 'brochure'

export const REGISTRATION_FORM='REGISTRATION_FORM'
export const AGENT_FORM='AGENT_FORM'

export const BASE_PATH = "/client/:clientName/community/:communityName"

export const BA_TEST_TRACKINGID = 'UA-105574325-16'

export const IPAD_PORTRAIT =
`only screen and (min-device-width: 768px) and (max-device-width: 1024px)
and (orientation: portrait)`

export const IPAD_LANDSCAPE =
`only screen  and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)`

export const NO_MATCH_BG ='https://res.cloudinary.com/renderinghouse/image/upload/v1541601192/anewgo/stock/ocean.jpg'
export const NO_MATCH_MSG='Oops. The page you are looking for is not found.'
