import { useRef } from 'react';
import { ApolloProvider } from '@apollo/client'
import { Provider as ReduxProvider } from 'react-redux'
import { apolloClient, configureStore, ConfigContext } from './store'
import { ThemeProvider } from './config'
import { BrowserRouter as Router } from 'react-router-dom'
import { createRoutes } from './routing'


import NoMatch from './routing/NoMatch'
import { NO_MATCH_BG } from './constants'

const store = configureStore()

const App = (props) => {
  const appRef = useRef(null)
  const userAgent = window.navigator.userAgent


  if (userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0) {
    return (
      <NoMatch
        bgImg={NO_MATCH_BG}
        msg={
          'Unfortunately, Internet Explorer is not supported. Please use a different browser.'
        }
      />
    )
  }

  return (
    <div
      ref={appRef}
      style={{
        width: '100vw',
        height: '100vh',
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      <ReduxProvider store={store}>
        <ApolloProvider client={apolloClient}>
          <Router>
            <ThemeProvider>
              <ConfigContext.Provider value={{ appRef }}>
                {createRoutes()}
              </ConfigContext.Provider>
            </ThemeProvider>
          </Router>
        </ApolloProvider>
      </ReduxProvider>
    </div>
  )
}

export default App
