// Common
export const EVT_USER_ALIAS = {
  name: 'User Alias',
  action: 'EVT_USER_ALIAS',
  schema: 'user-alias-event',
  version: '1-0-1',
}

export const EVT_TOGGLED_FAVORITES_MENU = {
  name: 'Toggled Favorites Menu',
  action: 'EVT_TOGGLED_FAVORITES_MENU',
  schema: 'toggle-event',
  version: '1-0-1',
}

export const EVT_LINKED_TO_FAVORITE = {
  name: 'Linked To Favorite',
  action: 'EVT_LINKED_TO_FAVORITE',
  schema: 'favorite-event',
  version: '1-0-1',
}

export const EVT_DELETED_FAVORITE = {
  name: 'Deleted Favorite',
  action: 'EVT_DELETED_FAVORITE',
  schema: 'favorite-event',
  version: '1-0-1',
}

export const EVT_TOGGLED_COMPARE_FAVORITES = {
  name: 'Toggled Compare Favorites',
  action: 'EVT_TOGGLED_COMPARE_FAVORITES',
  schema: 'toggle-event',
  version: '1-0-1',
}

// Signed in with at least one existing favorite from the same community.
// This implies that the user created the favorite on the MHA and signed in at the sales center.
export const EVT_EXTERNAL_FAVORITES_LOADED = {
  name: 'External Favorites Loaded',
  action: 'EVT_EXTERNAL_FAVORITES_LOADED',
  schema: 'external-favorites-loaded-event',
  version: '1-0-1',
}

// User Specific Events
export const EVT_CLICKED_AGENT_SIGN_IN = {
  name: 'Agent Clicked Sign In',
  action: 'EVT_AGENT_CLICKED_SIGN_IN',
  schema: 'referrer-event',
  version: '1-0-1',
}

// Agent Specific Events
export const EVT_CLICKED_USER_SIGN_IN = {
  name: 'User Clicked Sign In',
  action: 'EVT_USER_CLICKED_SIGN_IN',
  schema: 'referrer-event',
  version: '1-0-1',
}

// Interactive Floorplan events
export const EVT_CLICKED_FLOORPLAN_OPTION = {
  name: 'Clicked Floorplan Option',
  action: 'EVT_CLICKED_FLOORPLAN_OPTION',
  schema: 'interactive-floorplan-event',
  version: '1-0-1',
}

export const EVT_CLICKED_FLOORPLAN_MIRROR_OPTION = {
  name: 'Clicked Floorplan Mirror Option',
  action: 'EVT_CLICKED_FLOORPLAN_MIRROR_OPTION',
  schema: 'clicked-floorplan-mirror-option-event',
  version: '1-0-1',
}

// Interactive Exterior events
export const EVT_CLICKED_PALETTE_COLOR = {
  name: 'Clicked Palette Color',
  action: 'EVT_CLICKED_PALETTE_COLOR',
  schema: 'interactive-exterior-event',
  version: '1-0-3',
}

export const EVT_CLICKED_COLOR_SCHEME = {
  name: 'Clicked Color Scheme',
  action: 'EVT_CLICKED_COLOR_SCHEME',
  schema: 'interactive-exterior-event',
  version: '1-0-3',
}

export const EVT_CLICKED_RESET_COLOR_SCHEME = {
  name: 'Clicked Reset Color Scheme',
  action: 'EVT_CLICKED_RESET_COLOR_SCHEME',
  schema: 'interactive-exterior-event',
  version: '1-0-3',
}

// Plans page (active community filters can be inferred from the route)
export const EVT_CLICKED_PLAN_CARD = {
  name: 'Clicked Plan Card',
  action: 'EVT_CLICKED_PLAN_CARD',
  schema: 'clicked-card-event',
  version: '1-0-0',
}

export const EVT_CLICKED_ELEVATION_FROM_PLAN_CARD = {
  name: 'Clicked Elevation From Plan Card',
  action: 'EVT_CLICKED_ELEVATION_FROM_PLAN_CARD',
  schema: 'clicked-card-event',
  version: '1-0-0',
}

// Community POIs page
export const EVT_CLICKED_POI_OPTION = {
  name: 'Clicked POI Option',
  action: 'EVT_CLICKED_POI_OPTION',
  schema: 'clicked-poi-option-event',
  version: '1-0-2',
}

// Community Overview page
export const EVT_CLICKED_OVERVIEW_OPTION = {
  name: 'Clicked Overview Option',
  action: 'EVT_CLICKED_OVERVIEW_OPTION',
  schema: 'clicked-overview-option-event',
  version: '1-0-1',
}

// Floorplan page
export const EVT_CREATE_BROCHURE = {
  name: 'Clicked Create My Brochure',
  action: 'EVT_CLICKED_CREATE_MY_BROCHURE',
  schema: 'brochure-event',
  version: '1-0-1',
}

export const EVT_BROCHURE_SELECTED_COLORS = {
  name: 'My Brochure Selected Colors',
  action: 'EVT_MY_BROCHURE_SELECTED_COLORS',
  schema: 'brochure-selected-colors-event',
  version: '1-0-4',
}

export const EVT_BROCHURE_SELECTED_FPOPTS = {
  name: 'My Brochure Selected FpOpts',
  action: 'EVT_MY_BROCHURE_SELECTED_FPOPTS',
  schema: 'brochure-selected-fp-event',
  version: '1-0-2',
}

//Gallery Page
export const EVT_CLICKED_GALLERY_OPTION = {
  name: 'Clicked Gallery Option',
  action: 'EVT_CLICKED_GALLERY_OPTION',
  schema: 'clicked-gallery-option-event',
  version: '1-0-1',
}

//Pages
export const EVT_VIEW_PAGE = {
  name: 'Viewed Page',
  action: 'EVT_VIEW_PAGE',
  schema: 'page-view-event',
  version: '1-0-1',
}

// track footer
export const EVT_CLICKED_FOOTER_BUTTON = {
  name: 'Clicked Footer Button',
  action: 'EVT_CLICKED_FOOTER_BUTTON',
  schema: 'clicked-footer-button-event',
  version: '1-0-1',
}

// track image click
export const EVT_CLICKED_IMAGE = {
  name: 'Clicked Photo Gallery Image',
  action: 'EVT_CLICKED_PHOTO_GALLERY_IMAGE',
  schema: 'clicked-image-event',
  version: '1-0-0',
}
