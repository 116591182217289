import * as types from '../constants/actionTypes'

export const setAppConfig = (config) => ({
  type: types.SET_APP_CONFIG,
  config,
})

export const setMasterAppConfig = (config) => ({
  type: types.SET_MASTER_APP_CONFIG,
  config,
})

export const setClient = (client) => ({
  type: types.SET_CLIENT,
  client,
})

export const setMasterCommunityLogo = (masterCommunityLogo) => ({
  type: types.SET_MASTER_COMMUNITY_LOGO,
  masterCommunityLogo,
})

export const setMasterCommunityLogoViewBox = (masterCommunityLogoViewBox) => ({
  type: types.SET_MASTER_COMMUNITY_LOGO_VIEWBOX,
  masterCommunityLogoViewBox,
})

export const setSelectedMasterCommunity = (masterCommunity) => ({
  type: types.SET_SELECTED_MASTER_COMMUNITY,
  masterCommunity,
})

export const setDirectoryName = (directoryName) => ({
  type: types.SET_DIRECTORY_NAME,
  directoryName, // using redux as cache
})

export const setSiteplan = (siteplan) => ({
  type: types.SET_SITEPLAN,
  siteplan, // yes, we are using redux as a cache.
})

export const resetSelections = (includingCommunity) => ({
  type: types.RESET_SELECTIONS,
  includingCommunity,
})

export const resetSelectedLot = () => ({
  type: types.RESET_SELECTED_LOT,
})

export const setSelectedCommunity = (community) => ({
  type: types.SET_SELECTED_COMMUNITY,
  community,
})

export const setSelectedHome = ({ plan, elevation, fpOpts, lot, mirror, garagePosition }) => ({
  type: types.SET_SELECTED_HOME,
  payload: {
    plan,
    elevation,
    fpOpts,
    lot,
    mirror,
    garagePosition,
  },
})

export const deleteFavoriteByIndex = favorite => ({
  type: types.DELETE_FAVORITE_BY_INDEX,
  payload: favorite,
})

export const duplicateFavorites = favorites => ({
  type: types.DUPLICATE_FAVORITES,
  payload: favorites,
})

export const deleteAllFavorites = () => ({
  type: types.DELETE_ALL_FAVORITES,
})

export const restoreFavorite = favorite => ({
  type: types.RESTORE_FAVORITE,
  payload: favorite,
})

export const likeSelection = selection => ({
  type: types.LIKE_SELECTION,
  payload: selection,
})

export const setSelectedScheme = (scheme) => ({
  type: types.SET_SELECTED_SCHEME,
  scheme,
})

export const hydrateBrochureSelections = (dbSelections) => ({
  type: types.SET_BROCHURE_SELECTIONS,
  dbSelections,
})

export const resetSelectedScheme = () => ({
  type: types.RESET_SELECTED_SCHEME,
})

export const removeMaterialColor = (materialId) => ({
  type: types.REMOVE_MATERIAL_COLOR,
  materialId,
})

export const setMaterialColor = ({ material, color }) => ({
  type: types.SET_MATERIAL_COLOR,
  material,
  color,
})

export const clearMaterialColors = () => ({
  type: types.CLEAR_MATERIAL_COLORS,
})

export const resetSelectedFpOpts = () => ({
  type: types.RESET_SELECTED_FPOPTS,
})


export const setAgent = (agent) => ({
  type: types.SET_AGENT,
  agent,
})


export const setFnum = (fnum) => ({
  type: types.SET_FNUM,
  fnum,
})

export const addFpOpt = (fnum, fpOpt) => ({
  type: types.ADD_FP_OPT,
  fnum,
  fpOpt,
})

export const remFpOpt = (fnum, id) => ({
  type: types.REM_FP_OPT,
  fnum,
  id
})

export const setFpOpts = (fpOpts) => {
  return ({
    type: types.SET_FP_OPTS,
    fpOpts,
  })
}

export const setCommunityLogo = (communityLogo) => ({
  type: types.SET_COMMUNITY_LOGO,
  communityLogo,
})

export const setCommunityLogoWhite = (communityLogoWhite) => ({
  type: types.SET_COMMUNITY_LOGO_WHITE,
  communityLogoWhite,
})

export const setCommunityLogoViewBox = (communityLogoViewBox) => ({
  type: types.SET_COMMUNITY_LOGO_VIEWBOX,
  communityLogoViewBox,
})

export const setCommunityLogoWhiteViewBox = (communityLogoWhiteViewBox) => ({
  type: types.SET_COMMUNITY_LOGO_WHITE_VIEWBOX,
  communityLogoWhiteViewBox,
})

export const toggleMirror = (mirror) => ({
  type: types.TOGGLE_MIRROR,
  mirror,
})

export const toggleProspectFavoritesFetched = () => ({
  type: types.TOGGLE_PROSPECT_FAVORITES_FETCHED,
})

export const setAllFavoritesUpdated = () => ({
  type: types.SET_ALL_FAVORITES_UPDATED
})

export const updateFavoriteAfterDBUpdate = (favorite) => ({
  type: types.UPDATE_FAVORITE_AFTER_DB_UPDATE,
  payload: favorite
})

export const addNewFavorites = (favorites) => ({
  type: types.ADD_ALL_FAVORITES,
  payload: favorites
})

export const unlikeFavorite = favorite => ({
  type: types.UNLIKE_FAVORITE,
  payload: favorite,
})
